var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ReminderService } from "~/utils/reminder.service";
var createPrincipalService = function () { return import("~/services/manage-service/principal.service"); };
var createDepartmentService = function () { return import("~/services/manage-service/department.service"); };
var createColorService = function () { return import("~/services/manage-service/color.service"); };
var createResourceService = function () { return import("~/services/manage-service/resource.service"); };
export default {
    /**
     * 获取当前登陆人下的调收员
     */
    getCollector: function (_a) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var DepartmentService;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, createDepartmentService()];
                    case 1:
                        DepartmentService = (_b.sent()).DepartmentService;
                        DepartmentService.getInstance()
                            .queryCollectorByDepartCode()
                            .subscribe(function (data) {
                            commit("updateCollector", data);
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
    /**
     * 更新委托方列表
     */
    getPrincipalList: function (_a) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var PrincipalService;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, createPrincipalService()];
                    case 1:
                        PrincipalService = (_b.sent()).PrincipalService;
                        PrincipalService.getInstance()
                            .findAllPrincipalNoPage()
                            .subscribe(function (data) {
                            commit("updatePrincipalList", data);
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
    /**
     * 更新资源按钮
     */
    getResourcePower: function (_a) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var ResourceService;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, createResourceService()];
                    case 1:
                        ResourceService = (_b.sent()).ResourceService;
                        ResourceService.getInstance()
                            .findAllResource()
                            .subscribe(function (data) {
                            commit("updateResourcePower", data);
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
    /**
     * 获取部门数据
     * @param param0
     */
    getDepartmentList: function (_a) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var DepartmentService;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, createDepartmentService()];
                    case 1:
                        DepartmentService = (_b.sent()).DepartmentService;
                        DepartmentService.getInstance()
                            .findAllDepartment()
                            .subscribe(function (data) {
                            commit("OrgNameInfo", data);
                            commit("updateDepartmentList", data);
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
    /**
     * 更新受托方列表
     */
    getAllOutsource: function (_a) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                return [2 /*return*/];
            });
        });
    },
    /**
     * 更新打标颜色列表
     */
    getColorList: function (_a) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var ColorService;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, createColorService()];
                    case 1:
                        ColorService = (_b.sent()).ColorService;
                        ColorService.getInstance()
                            .findAllColor()
                            .subscribe(function (data) { return commit("updateColorList", data); });
                        return [2 /*return*/];
                }
            });
        });
    },
    /**
     * 更新用户登录数据
     */
    updateUserLoginData: function (_a, _b) {
        var state = _a.state, commit = _a.commit, dispatch = _a.dispatch;
        var token = _b.token, userModel = _b.userModel;
        // 更新用户token
        if (!!token) {
            commit("updateUserToken", token);
        }
        // 更新用户菜单资源
        commit("updateUserMenuResource", userModel.menu);
        // 更新用户控件资源
        commit("updateUserControlResource", userModel.resource);
        // 更新公司编号
        // commit('updateCompanyCode', user.companyCode);
        // 更新用户数据
        commit("updateUserData", {
            id: userModel.id,
            realName: userModel.realName,
            userName: userModel.username,
            isManager: userModel.isManager,
        });
        // 根据用户信息更新用户相关数据
        // 更新委托方数据
        dispatch("getPrincipalList");
        // 更新受托方数据
        dispatch("getAllOutsource");
        // 更新机构数据
        dispatch("getDepartmentList");
        dispatch("getResourcePower");
        // 更新案件打标颜色
        dispatch("getColorList");
        // 获取当前登陆人下的调收员
        dispatch("getCollector");
        // 连接消息推送
        ReminderService.connect();
    },
    /**
     * 清除登录数据
     */
    clearUserLoginData: function (_a) {
        var commit = _a.commit;
        // 重置用户token
        commit("updateUserToken", "");
        // 更新用户菜单资源
        commit("updateUserMenuResource", []);
        // 重置用户控件资源
        commit("updateUserControlResource", []);
        // 重置用户数据
        commit("updateUserData", {});
        // 重置受托方列表
        commit("updateOutSourceList", []);
        // 重置委托方列表
        commit("updatePrincipalList", []);
        // 更新公司编号
        commit("updateCompanyCode", "");
        //重置token过期标识
        commit("updateTokenExpire", false);
        //
        commit("updateCurrentTopMenu", "");
        // 重置动态菜单
        commit("updateDynamicMenus", []);
        // 连接消息推送
        ReminderService.disConnect();
    },
};
