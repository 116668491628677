var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { commonService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject } from "~/core/decorator";
import { Service } from "~/core/service";
var TaskBoxService = /** @class */ (function (_super) {
    __extends(TaskBoxService, _super);
    function TaskBoxService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 获取任务列表
     * @param page 分页参数
     * @param sort 排序参数
     */
    TaskBoxService.prototype.queryTaskBox = function (page, sort, loading) {
        return this.netService.send({
            server: commonService.taskBoxController.queryTaskBox,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 添加任务信息
     * @param data 查询参数实体
     */
    TaskBoxService.prototype.sendTaskByUserId = function (data, loading) {
        return this.netService.send({
            server: commonService.taskBoxController.sendTaskByUserId,
            data: data,
            loading: loading
        });
    };
    /**
     * 删除消息
     * @param data
     * @param loading
     */
    TaskBoxService.prototype.deletedTaskBox = function (data, loading) {
        return this.netService.send({
            server: commonService.taskBoxController.deletedTaskBox,
            data: { taskBoxIdList: data },
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], TaskBoxService.prototype, "netService", void 0);
    return TaskBoxService;
}(Service));
export { TaskBoxService };
