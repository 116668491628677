import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "colorController";
export default {
    /**
     * 获取全部颜色
     */
    findAllColor: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllColor',
        type: requestType.Get
    }
};
