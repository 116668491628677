var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var CreateDepartmentService = function () { return import("~/services/manage-service/department.service"); };
var CaseStatusServices = function () { return import("~/services/manage-service/contact-config.service"); };
import { CommonService } from "~/utils/common.service";
export default {
    namespaced: true,
    state: {
        modulePermissRoleId: "",
        modulePermissDialog: false,
        modulePermissRoleCode: "",
        orgInfo: [],
        companyName: [],
        contactInfo: [],
        currentUser: "",
    },
    getters: {
        orgTreeData: function (state) {
            var orgList = state.orgInfo.map(function (v) { return (__assign({}, v)); });
            var option = {
                keyName: "id",
                parentKeyName: "parent",
            };
            return CommonService.generateTreeData(orgList, option);
        },
        contactTreeData: function (state) {
            var contactList = state.contactInfo.map(function (v) {
                return {
                    id: v.id,
                    parent: v.parent,
                    name: v.name,
                    sort: v.sort,
                    level: v.level,
                    principalId: v.principalId,
                    principalName: v.principalName,
                };
            });
            var option = {
                keyName: "id",
                parentKeyName: "parent",
                sortKey: "sort",
            };
            return CommonService.generateTreeData(contactList, option);
        },
    },
    mutations: {
        // 用户角色Id
        updateModuleRoleId: function (state, val) {
            state.modulePermissRoleId = val;
        },
        updateModuleRoleCode: function (state, val) {
            state.modulePermissRoleCode = val;
        },
        updateModuleDialog: function (state, val) {
            state.modulePermissDialog = val;
        },
        /**
         * 更新组织名称信息
         * @param {*} state
         * @param {*} value
         */
        OrgNameInfo: function (state, value) {
            state.orgInfo = value;
        },
        /**
         * 更新联络调收信息
         * @param {*} state
         * @param {*} value
         */
        ContactNameInfo: function (state, value) {
            state.contactInfo = value;
        },
    },
    actions: {
        /**
         * 更新组织名称信息
         */
        updateOrgNameInfo: function (_a) {
            var state = _a.state, commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var DepartmentService;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, CreateDepartmentService()];
                        case 1:
                            DepartmentService = (_b.sent()).DepartmentService;
                            DepartmentService.getInstance()
                                .findAllDepartment()
                                .subscribe(function (data) {
                                commit("OrgNameInfo", data);
                                // 调用根节点的mutation
                                commit("updateDepartmentList", data, { root: true });
                            });
                            return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * 更新联络调收信息
         */
        updateContactNameInfo: function (_a) {
            var state = _a.state, commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var ContactConfigService;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, CaseStatusServices()];
                        case 1:
                            ContactConfigService = (_b.sent()).ContactConfigService;
                            ContactConfigService.getInstance()
                                .getContactConfigTree()
                                .subscribe(function (data) {
                                commit("ContactNameInfo", data);
                            });
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
};
