import { requestType } from '~/config/enum.config';
var SERVICE = 'domain-service';
var CONTROLLER = 'caseAnnexController';
export default {
    /**
     * 案件补充资料
     */
    getCaseAnnexDetail: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getCaseAnnexDetail',
        type: requestType.Get
    }
};
