import { requestType } from '~/config/enum.config';
var SERVICE = 'repair-service';
var CONTROLLER = 'relationship';
export default {
    /**
     * 查询关联关系
     */
    search: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'search',
        type: requestType.Get
    },
    /**
    * 增加关联关系
    */
    insert: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'insert',
        type: requestType.Post
    },
    /**
    * 删除关联关系
    */
    delete: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'delete',
        type: requestType.Get
    },
    /**
    * 修改关联关系
    */
    update: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'update',
        type: requestType.Post
    },
    /**
     * 修改关联关系
     */
    insertBatch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'insertBatch',
        type: requestType.Post
    },
    /**
     * 按导入批次删除户籍资料
     */
    deleteByBatch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteByBatch',
        type: requestType.Get
    }
};
