import { render, staticRenderFns } from "./workspace.layout.vue?vue&type=template&id=116b5bb6&scoped=true"
import script from "./workspace.layout.vue?vue&type=script&lang=ts"
export * from "./workspace.layout.vue?vue&type=script&lang=ts"
import style0 from "./workspace.layout.vue?vue&type=style&index=0&id=116b5bb6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "116b5bb6",
  null
  
)

export default component.exports