import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'homePageController';
export default {
    /**
     * 账户标记
     */
    getCollState: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getCollState',
        type: requestType.Get,
    },
    /**
     * 查询备忘录
     */
    getComment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getComment',
        type: requestType.Get,
    },
    /**
     * 小组月进度
     */
    getGroupMonthSchedule: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getGroupMonthSchedule',
        type: requestType.Get,
    },
    /**
     * 个人月进度
     */
    getPersonalMonthSchedule: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getPersonalMonthSchedule',
        type: requestType.Get,
    },
    /**
     * 承诺还款记录
     */
    getPTPRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getPTPRecord',
        type: requestType.Get,
    },
    /**
     * 总存量
     */
    getStock: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getStock',
        type: requestType.Get,
    }
};
