var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { manageService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var UserService = /** @class */ (function (_super) {
    __extends(UserService, _super);
    function UserService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 用户登录
     */
    UserService.prototype.login = function (data, loading) {
        return this.netService.send({
            server: manageService.userController.login,
            data: data,
            loading: loading,
        });
    };
    /**
     * 用户登出
     */
    UserService.prototype.logout = function (loading) {
        return this.netService.send({
            server: manageService.userController.logout,
        });
    };
    /**
     * 新增用户
     */
    UserService.prototype.addUser = function (data, loading) {
        return this.netService.send({
            server: manageService.userController.addUser,
            data: data,
            loading: loading,
        });
    };
    /**
     * 修改用户
     */
    UserService.prototype.modifyUser = function (data, loading) {
        return this.netService.send({
            server: manageService.userController.modifyUser,
            data: data,
            loading: loading,
        });
    };
    /**
     * 删除用户
     */
    UserService.prototype.deleteUser = function (data, loading) {
        return this.netService.send({
            server: manageService.userController.deleteUser,
            data: data,
            loading: loading,
        });
    };
    /**
     * 重置/修改密码
     */
    UserService.prototype.resetPassword = function (data, loading) {
        return this.netService.send({
            server: manageService.userController.resetPassword,
            data: data,
            loading: loading,
        });
    };
    /**
     * 多条件查询用户
     */
    UserService.prototype.findUserByDepartment = function (data, page, loading) {
        return this.netService.send({
            server: manageService.userController.findUserByDepartment,
            data: data,
            page: page,
            loading: loading,
        });
    };
    /**
     * 查询用户详情
     */
    UserService.prototype.getUserDetail = function (userId, loading) {
        return this.netService.send({
            server: manageService.userController.getUserDetail,
            data: {
                userId: userId,
            },
            loading: loading,
        });
    };
    /**
     * 通过token获取用户
     */
    UserService.prototype.getUserByToken = function (token, loading) {
        return this.netService.send({
            server: manageService.userController.getUserByToken,
            append: [token],
            loading: loading,
        });
    };
    /**
     * 查询用户所分配角色
     */
    UserService.prototype.getRoles = function (userId, loading) {
        return this.netService.send({
            server: manageService.userController.getRoles,
            data: {
                userId: userId,
            },
            loading: loading,
        });
    };
    /**
     * 修改密码
     */
    UserService.prototype.updatePassword = function (data, loading) {
        return this.netService.send({
            server: manageService.userController.updatePassword,
            data: data,
            loading: loading,
        });
    };
    /**
     * 用户解锁
     */
    UserService.prototype.unlock = function (userId, loading) {
        return this.netService.send({
            server: manageService.userController.unlock,
            data: {
                userId: userId,
            },
            loading: loading,
        });
    };
    /**
     * 一键解锁
     */
    UserService.prototype.batchUnlock = function (loading) {
        return this.netService.send({
            server: manageService.userController.batchUnlock,
            loading: loading,
        });
    };
    /**
     * 根据部门code查询用户
     */
    UserService.prototype.findUserByDepartmentCode = function (data, sort) {
        return this.netService.send({
            server: manageService.userController.findUserByDepartmentCode,
            data: data,
            sort: sort,
        });
    };
    /**
     * 根据部门code查询调收员
     */
    UserService.prototype.getSimpleUser = function (data) {
        return this.netService.send({
            server: manageService.userController.getSimpleUser,
            data: {
                departCode: data,
            },
        });
    };
    __decorate([
        Inject(NetService)
    ], UserService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], UserService.prototype, "login", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "logout", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "addUser", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "modifyUser", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "deleteUser", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "resetPassword", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "findUserByDepartment", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "getUserDetail", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "getUserByToken", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "getRoles", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "unlock", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "batchUnlock", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "findUserByDepartmentCode", null);
    __decorate([
        Debounce()
    ], UserService.prototype, "getSimpleUser", null);
    return UserService;
}(Service));
export { UserService };
