import { requestType } from "~/config/enum.config";
var SERVICE = "dataimp-service";
var CONTROLLER = "dataExportController";
export default {
    /**
     * 工单导出
     */
    exportWorkOrder: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportWorkOrder",
        type: requestType.Get,
    },
    /**
     * 案件导出
     */
    exportCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportCase",
        type: requestType.Get,
    },
    /**
     * 调记导出
     */
    exportFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportFollowRecord",
        type: requestType.Get,
    },
    /**
     * 联系人导出
     */
    exportContact: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportContact",
        type: requestType.Get,
    },
    /**
     * 分案导出
     */
    exportDistribute: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportDistribute",
        type: requestType.Get,
    },
};
