import { requestType } from '~/config/enum.config';
var SERVICE = 'management-service';
var CONTROLLER = 'operators';
export default {
    /**
     * 账号密码登录
     */
    login: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'login',
        type: requestType.Post
    },
    /**
     * 用户查询
     */
    operatorsQuery: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'query',
        type: requestType.Get
    },
    /**
     * 用户新增
     */
    createOperator: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'createOperator',
        type: requestType.Post
    },
    /**
     * 用户修改
     */
    modify: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modify',
        type: requestType.Post
    },
    /**
     * token登录
     */
    getUserByToken: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getUserByToken',
        type: requestType.Get
    },
    /**
     * 修改/重置密码
     * 重置密码只传参用户id，修改密码就传值id,newpassword,oldpassowrd
     */
    updatePassword: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'updatePassword',
        type: requestType.Post
    },
    /**
   * 用户解锁
   * 用于密码输入3次被锁定后解锁，解锁只传参用户id
   */
    recoverLockedStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'recoverLockedStatus',
        type: requestType.Get
    },
    /**
     * 一键启用停用设备锁
     */
    updateDeviceLock: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'updateDeviceLock',
        type: requestType.Get
    },
    /**
     * 用户分配角色
     */
    operatorAddRole: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'operatorAddRole',
        type: requestType.Post
    },
    getUserRoleId: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getUserRoleId',
        type: requestType.Get
    },
    /**
     * 获取IM用户列表
     */
    getUserListForIM: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getUserListForIM',
        type: requestType.Get
    },
    /**
     * 用户登出
     */
    operatorSignOut: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'operatorSignOut',
        type: requestType.Get
    },
    /**
     * 修改座机
     */
    updateSeatNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'updateSeatNumber',
        type: requestType.Post
    },
    /**
     * 修改座机
     */
    getOperator: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getOperator',
        type: requestType.Get
    },
    /**
     * 批量删除用户角色
     */
    removeAllOperatorRole: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'removeAllOperatorRole',
        type: requestType.Get
    },
    /**
     * 一键解锁
     */
    recoverAllLockedStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'recoverAllLockedStatus',
        type: requestType.Get
    },
    /**
     * 一键重置密码
     */
    updateAllPassword: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'updateAllPassword',
        type: requestType.Get
    }
};
