import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "userDeviceController";
export default {
    /**
     * 设备锁查询
     */
    findAllUserDevice: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllUserDevice',
        type: requestType.Get
    },
    /**
     * 一键启/禁用设备锁
     */
    batchDeviceManage: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'batchDeviceManage',
        type: requestType.Get
    },
    /**
     * 启用/禁用设备
     */
    modifyStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyStatus',
        type: requestType.Put
    },
    /**
     * 重置设备
     */
    deleteUserDevice: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteUserDevice',
        type: requestType.Delete
    }
};
