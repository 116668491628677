import { requestType } from '~/config/enum.config';
var SERVICE = 'business-service';
var CONTROLLER = 'warningController';
export default {
    /**
     * 添加警告
     */
    addWarning: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addWarning',
        type: requestType.Post
    },
    /**
     * 修改警告
     */
    modifyWarning: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyWarning',
        type: requestType.Put
    },
    /**
     * 删除警告
     */
    deleteWarning: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteWarning',
        type: requestType.Delete
    },
    /**
     * 查询警告
     */
    findAllWarning: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllWarning',
        type: requestType.Get
    },
    /**
   * 根据caseId查询警告
   */
    getWarningByCaseId: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getWarningByCaseId',
        type: requestType.Get
    },
};
