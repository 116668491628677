import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'caseDistributeController';
export default {
    /**
     * 区域分案预览
     */
    areaCaseDistributePre: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'areaCaseDistributePre',
        type: requestType.Post
    },
    /**
     * 区域分案确认
     */
    areaConfirmDistributeCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'areaConfirmDistributeCase',
        type: requestType.Post
    },
    /**
     * 区域策略方案
     */
    strategyDistributeCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'strategyDistributeCase',
        type: requestType.Post
    },
    /**
     * 个人分案预览
     */
    groupCaseDistributePre: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'groupCaseDistributePre',
        type: requestType.Post
    },
    /**
     * 个人分案确认
     */
    groupConfirmDistributeCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'groupConfirmDistributeCase',
        type: requestType.Post
    },
    /**
     * 分配展示
     */
    assistCaseDistributePre: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'assistCaseDistributePre',
        type: requestType.Post
    },
    /**
     * 分配确认
     */
    assistConfirmDistributeCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'assistConfirmDistributeCase',
        type: requestType.Post
    },
    /**
     * 区域批量分案预览
     */
    areaCaseBatchDistribute: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'areaCaseBatchDistribute',
        type: requestType.Post
    },
    /**
     * 地区批量分配中确认
     */
    areaConfirmBatchDistribute: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'areaConfirmBatchDistribute',
        type: requestType.Post
    },
    /**
     * 个人批量分案预览
     */
    groupCaseBatchDistribute: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'groupCaseBatchDistribute',
        type: requestType.Post
    },
    /**
     * 个人批量分配中确认
     */
    groupBatchConfirmDistribute: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'groupBatchConfirmDistribute',
        type: requestType.Post
    },
    /**
     * 个人分案预览
     */
    groupCaseDistributeRenew: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'groupCaseDistributeRenew',
        type: requestType.Post
    },
    /**
     * 个案分案中确认
     */
    groupConfirmCaseDistributeRenew: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'groupConfirmCaseDistributeRenew',
        type: requestType.Post
    },
    /**
     * 个案批量分案预览
     */
    groupCaseBatchRenew: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'groupCaseBatchRenew',
        type: requestType.Post
    },
    /**
     * 个案批量分案中确认
     */
    groupBatchConfirmDistributeRenew: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'groupBatchConfirmDistributeRenew',
        type: requestType.Post
    },
    /**
     * 个案批量分案中确认
     */
    checkCustomerInDiffArea: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'checkCustomerInDiffArea',
        type: requestType.Get
    }
};
