var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import store from "~/store";
import SvgIcon from "~/components/common/svg-icon.vue";
import { Dependencies } from "~/core/decorator";
import { WebSocketMsgService } from "~/services/common-service/websocket-msg.service";
import MessageDetail from "~/components/workspace/message/message-detail.vue";
var WorkReminder = /** @class */ (function (_super) {
    __extends(WorkReminder, _super);
    function WorkReminder() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.messageList = [];
        _this.currentMessage = {};
        _this.dialog = {
            messageDetail: false,
            close: false
        };
        _this.store = store;
        return _this;
    }
    Object.defineProperty(WorkReminder.prototype, "commit", {
        get: function () {
            return this.store._modulesNamespaceMap["workspace/"].context.commit;
        },
        enumerable: false,
        configurable: true
    });
    WorkReminder.prototype.getReminderStyle = function (type) {
        var reminderTypeStyle = {
            UnRead: {
                color: "#ff5722"
            },
            Read: {
                color: "#0092dc"
            }
        };
        return reminderTypeStyle[type];
    };
    WorkReminder.prototype.refreshData = function () {
        var _this = this;
        this.webSocketMsgService.getWorkbenchReminder().subscribe(function (data) {
            _this.messageList = data.content;
        });
    };
    WorkReminder.prototype.mounted = function () {
        this.refreshData();
    };
    __decorate([
        Dependencies(WebSocketMsgService)
    ], WorkReminder.prototype, "webSocketMsgService", void 0);
    WorkReminder = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon,
                MessageDetail: MessageDetail
            }
        })
    ], WorkReminder);
    return WorkReminder;
}(Vue));
export default WorkReminder;
