import { requestType } from '~/config/enum.config';
var SERVICE = 'business-service';
var CONTROLLER = 'caseTransferLogController';
export default {
    /**
     * 查询流转记录
     */
    getAllCaseTransferLog: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getAllCaseTransferLog',
        type: requestType.Get
    }
};
