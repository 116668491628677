import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'importTemplateController';
export default {
    /**
     * 新增模板
     */
    createTemplateData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "createTemplateData",
        type: requestType.Post
    },
    /**
     * 修改模板详情
     */
    templateDataModify: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "templateDataModify",
        type: requestType.Post
    },
    /**
     * 删除模板
     */
    deleteTemplateData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteTemplateData",
        type: requestType.Delete
    },
    /**
     * 获取模板详情
     */
    getTemplateData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getTemplateData",
        type: requestType.Get
    },
    /**
     * 委模板查询
     */
    templateDataQuery: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "templateDataQuery",
        type: requestType.Get
    },
    /**
     * 解析Excel数据模板头信息
     */
    parserTemplateHeader: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "parserTemplateHeader",
        type: requestType.Post
    },
    /**
     * Excel数据导入模板
     */
    createImportExcelConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "createImportExcelConfig",
        type: requestType.Post
    },
    importExcelConfigModify: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importExcelConfigModify",
        type: requestType.Post
    },
};
