import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "departmentController";
export default {
    /**
     * 新增部门
     */
    addDepartment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addDepartment",
        type: requestType.Post
    },
    /**
     * 修改部门
     */
    modifyDepartment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "modifyDepartment",
        type: requestType.Put
    },
    /**
     * 删除部门
     */
    deleteDepartment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteDepartment",
        type: requestType.Delete
    },
    /**
     * 获取全部部门
     */
    findAllDepartment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllDepartment",
        type: requestType.Get
    },
    /**
   * 获取所有的分公司
   */
    findAllBranch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllBranch',
        type: requestType.Get
    },
    /**
     * 获取所有的分公司
     */
    queryCollectorByDepartCode: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryCollectorByDepartCode',
        type: requestType.Get
    }
};
