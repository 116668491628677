import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'homeManagerController';
export default {
    /**
     * 获取管理员统计数据
     */
    getCountInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getCountInfo',
        type: requestType.Get,
    },
    /**
     * 获取管理员总存量
     */
    getStock: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getStock',
        type: requestType.Get,
    },
};
