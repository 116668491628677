export default {
    namespaced: true,
    state: {
        tacticsFlag: false
    },
    mutations: {
        updateTacticsFlag: function (state, value) {
            state.tacticsFlag = value;
        }
    },
    actions: {}
};
