import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'caseChangeController';
export default {
    /**
     * 修改案件信息
     */
    changeCaseInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'changeCaseInfo',
        type: requestType.Post
    },
    /**
     * 查询修改信息
     */
    queryCaseInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryCaseInfo',
        type: requestType.Get
    }
};
