import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "strategyController";
export default {
    /**
     * 多条件查询分案策略
     */
    findAllStrategy: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllStrategy',
        type: requestType.Get
    },
    /**
     * 新增策略
     */
    addStrategy: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addStrategy',
        type: requestType.Post
    },
    /**
     * 删除策略
     */
    deleteStrategy: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteStrategy',
        type: requestType.Delete
    },
    /**
     * 修改策略
     */
    modifyStrategy: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyStrategy',
        type: requestType.Put
    },
    /**
     * 待分配案件池策略预览
     */
    strategyPreview: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'strategyPreview',
        type: requestType.Get
    },
    /**
     * 其余策略预览
     */
    caseStrategyPreview: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'caseStrategyPreview',
        type: requestType.Get
    },
    /**
     * 获取策略
     */
    getStrategy: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getStrategy',
        type: requestType.Get
    },
    /**
     * 复制策略
     */
    copyStrategy: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'copyStrategy',
        type: requestType.Get
    },
};
