var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop, Emit } from "vue-property-decorator";
var DataBox = /** @class */ (function (_super) {
    __extends(DataBox, _super);
    function DataBox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.num = 20;
        return _this;
    }
    // 分页信息改变消息
    DataBox.prototype.pageConfigChange = function (page) { };
    // 更新选择项列表
    DataBox.prototype.updateSelectionList = function (list) { };
    // 更新当前选择项
    DataBox.prototype.updateSelectionRow = function (row) { };
    DataBox.prototype.onRowClick = function (row, event, column) { };
    DataBox.prototype.onExpandChange = function (row, expandedRows) { };
    // 监听当前选中行变化
    DataBox.prototype.onSelectionRowChange = function (val, oldVal) {
        var _this = this;
        this.$nextTick(function () {
            _this.table.setCurrentRow(val);
        });
    };
    DataBox.prototype.onDataChanged = function (newVal, oldVal) {
        var _this = this;
        if (!this.data.length && this.page) {
            if (this.page.totalPage && this.page.totalPage < this.page.pageIndex) {
                this.page.pageIndex = this.page.totalPage;
                setTimeout(function () {
                    _this.pageConfigChange(_this.page);
                }, 500);
            }
        }
    };
    // 监听当前选中列表变化
    DataBox.prototype.onSelectionListChanged = function (val, oldVal) {
        var _this = this;
        if (!val) {
            return;
        }
        //  等待data 渲染完毕之后再进行选中行赋值
        this.$nextTick(function () {
            // 待删除检测
            val.forEach(function (row) {
                var target = _this.table.store.states.selection.find(function (x) { return x === row; });
                if (!target) {
                    _this.table.toggleRowSelection(row, true);
                }
            });
            // 待删除检测
            _this.table.store.states.selection.forEach(function (row) {
                var target = _this.table.store.states.selection.find(function (x) { return x === row; });
                if (!target) {
                    _this.table.toggleRowSelection(row, false);
                }
            });
        });
    };
    DataBox.prototype.getRowKeys = function (row) {
        return row.id;
    };
    DataBox.prototype.tableHeight = function () {
        if (this.height === "") {
            return this.maxHeight;
        }
        else {
            return this.height;
        }
    };
    Object.defineProperty(DataBox.prototype, "emptyText", {
        get: function () {
            return this.data ? "暂无数据" : " ";
        },
        enumerable: false,
        configurable: true
    });
    /**
     * 组件初始化
     */
    DataBox.prototype.mounted = function () {
        this.table = this.$refs["table"];
    };
    /**
     * 页码数量变化回调
     */
    DataBox.prototype.pageSizeChange = function (value) {
        this.page.pageIndex = 1;
        this.page.pageSize = value;
        this.pageConfigChange(this.page);
    };
    /**
     * 页码位置变化回调
     */
    DataBox.prototype.pageIndexChange = function (value) {
        this.page.pageIndex = value;
        this.pageConfigChange(this.page);
    };
    /**
     * 排序改变
     */
    DataBox.prototype.onSortChange = function (_a) {
        var column = _a.column, prop = _a.prop, order = _a.order;
        if (this.sort) {
            this.sort.update(prop, order);
            this.pageConfigChange(this.page);
        }
    };
    /**
     *  清楚选择项
     */
    DataBox.prototype.clearSelection = function () {
        this.table.clearSelection();
        this.table.setCurrentRow();
    };
    Object.defineProperty(DataBox.prototype, "showTopSlot", {
        get: function () {
            return this.$slots.append || this.$slots.buttons;
        },
        enumerable: false,
        configurable: true
    });
    DataBox.prototype.spanMethod = function (_a) {
        var _this = this;
        var row = _a.row, column = _a.column, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
        if (!this.data.length)
            return;
        // 如果有自定义合并方法，则优先
        if (this.customSpanMethod !== undefined) {
            return this.customSpanMethod({ row: row, column: column, rowIndex: rowIndex, columnIndex: columnIndex });
        }
        // 没有设定合并键则不合并
        if (!this.rowSpanKeys.length)
            return;
        var columnProp = column.property;
        if (!this.rowSpanKeys.includes(columnProp))
            return;
        /**
         * 获取单元格的值
         */
        var getColumnValue = function (index) {
            return (_this.data[index] || {})[columnProp];
        };
        var currentValue = row[columnProp];
        // 如果当前单元格的值与上一单元格的值一致，认为计算过
        var beforValue = getColumnValue(rowIndex - 1);
        if (beforValue && currentValue === beforValue)
            return [0, 1];
        // 开始计算需要合并几行
        var rowSpanCount = 1;
        while (rowIndex++ < this.data.length &&
            getColumnValue(rowIndex) === currentValue) {
            rowSpanCount++;
        }
        return [rowSpanCount, 1];
    };
    __decorate([
        Prop()
    ], DataBox.prototype, "data", void 0);
    __decorate([
        Prop()
    ], DataBox.prototype, "page", void 0);
    __decorate([
        Prop({ default: true })
    ], DataBox.prototype, "showPage", void 0);
    __decorate([
        Prop({ default: false })
    ], DataBox.prototype, "reserveSelection", void 0);
    __decorate([
        Prop()
    ], DataBox.prototype, "sort", void 0);
    __decorate([
        Prop()
    ], DataBox.prototype, "selectionList", void 0);
    __decorate([
        Prop()
    ], DataBox.prototype, "selectionRow", void 0);
    __decorate([
        Prop({ default: 628 })
    ], DataBox.prototype, "maxHeight", void 0);
    __decorate([
        Prop()
    ], DataBox.prototype, "height", void 0);
    __decorate([
        Prop({ type: Function })
    ], DataBox.prototype, "rowStyle", void 0);
    __decorate([
        Prop({ type: Function })
    ], DataBox.prototype, "rowClass", void 0);
    __decorate([
        Prop({ type: Function })
    ], DataBox.prototype, "cellStyle", void 0);
    __decorate([
        Prop({ type: Boolean })
    ], DataBox.prototype, "hiddenIndex", void 0);
    __decorate([
        Prop({ type: Array, default: function () { return []; } })
    ], DataBox.prototype, "rowSpanKeys", void 0);
    __decorate([
        Prop({
            type: String,
            default: "序号"
        })
    ], DataBox.prototype, "indexLabel", void 0);
    __decorate([
        Prop({
            type: Function
        })
    ], DataBox.prototype, "customSpanMethod", void 0);
    __decorate([
        Prop({ type: Boolean })
    ], DataBox.prototype, "showSummary", void 0);
    __decorate([
        Prop({ type: Function })
    ], DataBox.prototype, "summaryMethod", void 0);
    __decorate([
        Emit("onPageChange")
    ], DataBox.prototype, "pageConfigChange", null);
    __decorate([
        Emit("update:selectionList")
    ], DataBox.prototype, "updateSelectionList", null);
    __decorate([
        Emit("update:selectionRow")
    ], DataBox.prototype, "updateSelectionRow", null);
    __decorate([
        Emit("onRowClick")
    ], DataBox.prototype, "onRowClick", null);
    __decorate([
        Emit("onExpandChange")
    ], DataBox.prototype, "onExpandChange", null);
    __decorate([
        Watch("selectionRow")
    ], DataBox.prototype, "onSelectionRowChange", null);
    __decorate([
        Watch("data")
    ], DataBox.prototype, "onDataChanged", null);
    __decorate([
        Watch("selectionList")
    ], DataBox.prototype, "onSelectionListChanged", null);
    DataBox = __decorate([
        Component({
            components: {}
        })
    ], DataBox);
    return DataBox;
}(Vue));
export default DataBox;
