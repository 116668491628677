export default {
    namespaced: true,
    state: {
        caseAllotUser: [],
        caseAllotOrg: {},
        /**
         * 选中的案件，要分配的案件的ID
        */
        selectedCase: []
    },
    mutations: {
        updateCaseAllotUser: function (state, user) {
            state.caseAllotUser = user || [];
        },
        updateCaseAllotOrg: function (state, org) {
            state.caseAllotOrg = org;
        },
        /**
         * 更新state里面选中的案件
         * @param state
         * @param someCase 选择的案件编号组数
         */
        updateSelectedCase: function (state, someCase) {
            state.selectedCase = someCase || [];
        }
    },
    actions: {}
};
