import { requestType } from '~/config/enum.config';
var SERVICE = 'business-service';
var CONTROLLER = 'paymentRecordController';
export default {
    /**
     * 查询还款记录
     */
    findPaymentRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findPaymentRecord',
        type: requestType.Get
    },
    /**
     * 查询还款记录列表
     */
    findAllPaymentRecords: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllPaymentRecords',
        type: requestType.Get
    },
    /**
     * 查询共债对账信息
     */
    caseBillRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'caseBillRecord',
        type: requestType.Get
    }
};
