import { requestType } from '~/config/enum.config';
var SERVICE = 'repair-service';
var CONTROLLER = 'dataRepairImport';
export default {
    /**
     * 查询支付宝信息
     */
    import: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'import',
        type: requestType.Post
    },
    /**
     * 查询通讯类型
     */
    getDataTypes: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getDataTypes',
        type: requestType.Get
    },
    /**
     * 导入比较
     */
    importCompare: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'importCompare',
        type: requestType.Post
    },
    /**
   * 下载模板
   */
    getSystemTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getSystemTemplate',
        type: requestType.Get
    }
};
