import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "userController";
export default {
    /**
     * 用户登录
     */
    login: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "login",
        type: requestType.Post
    },
    /**
     * 用户登出
     */
    logout: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'logout',
        type: requestType.Get
    },
    /**
     * 新增用户
     */
    addUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addUser",
        type: requestType.Post
    },
    /**
     * 修改用户
     */
    modifyUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "modifyUser",
        type: requestType.Put
    },
    /**
     * 删除用户
     */
    deleteUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteUserById",
        type: requestType.Delete
    },
    /**
     * 重置/修改密码
     */
    resetPassword: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "resetPassword",
        type: requestType.Put
    },
    /**
     * 重置/修改密码
     */
    updatePassword: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updatePassword",
        type: requestType.Post
    },
    /**
     * 多条件查询用户
     */
    findUserByDepartment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findUserByDepartment",
        type: requestType.Get
    },
    /**
     * 查询用户详情
     */
    getUserDetail: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getUserDetail",
        type: requestType.Get
    },
    /**
     * 通过token获取用户
     */
    getUserByToken: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getUserByToken',
        type: requestType.Get
    },
    /**
     * 查询用户所分配角色
     */
    getRoles: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getRoles',
        type: requestType.Get
    },
    /**
     * 用户解锁
     */
    unlock: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'unlock',
        type: requestType.Get
    },
    /**
     * 一键解锁
     */
    batchUnlock: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'batchUnlock',
        type: requestType.Get
    },
    /**
     * 根据部门code查询用户
     */
    findUserByDepartmentCode: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findUserByDepartmentCode',
        type: requestType.Get
    },
    /**
     * 根据部门code查询用户
     */
    getSimpleUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getSimpleUser',
        type: requestType.Get
    },
};
