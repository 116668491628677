import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "principalController";
export default {
    /**
     * 新增委托方
     */
    addPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addPrincipal",
        type: requestType.Post
    },
    /**
     * 修改委托方
     */
    modifyPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "modifyPrincipal",
        type: requestType.Put
    },
    /**
     * 多条件查询委托方
     */
    findAllPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllPrincipal",
        type: requestType.Get
    },
    /**
     * 查询全部委托方
     */
    findAllPrincipalNoPage: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllPrincipalNoPage",
        type: requestType.Get
    }
};
