import { requestType } from "~/config/enum.config";
var SERVICE = "report-service";
var CONTROLLER = "CaseInfoInquiryController";
export default {
    /**
     * 短信调收列表
     */
    getCaseInfoByNoPower: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getCaseInfoByNoPower",
        type: requestType.Get,
    },
    /**
     * PC电调和外访
     */
    getCaseInfoByCondition: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getCaseInfoByCondition",
        type: requestType.Get,
    },
    /**
     * PC协调
     */
    getCaseAssistByCondition: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getCaseAssistByCondition",
        type: requestType.Get,
    },
    /**
     * 内调按批次号查询调收中案件
     */
    getInnerCaseInfoByCondition: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getInnerCaseInfoByCondition",
        type: requestType.Get,
    },
    /**
     * 获取产品系列和产品名称
     */
    getProductSeries: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getProductSeries",
        type: requestType.Get,
    },
    /**
     * 查询内调待分配
     */
    getInnerWaitCollectCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getInnerWaitCollectCase",
        type: requestType.Get,
    },
    /**
     * 多条件查询司法案件
     */
    getCaseInfoJudicialByCondition: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getCaseInfoJudicialByCondition",
        type: requestType.Get,
    },
    /**
     * 多条件查询修复案件
     */
    getAllRepairingCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllRepairingCase",
        type: requestType.Get,
    },
};
