export default {
    namespaced: true,
    state: {
        agentstatus: '0',
        isSignin: false,
        seatState: '-2',
        saveUUID: ''
    },
    mutations: {
        updateAgentstatus: function (state, value) {
            state.agentstatus = value;
        },
        updateIsSignin: function (state, value) {
            state.isSignin = value;
        },
        updateSeatState: function (state, value) {
            state.seatState = value;
        },
        updateUUid: function (state, value) {
            state.saveUUID = value;
        }
    },
    actions: {
    // clearOutboundData({ commit }) {
    //
    //   commit('updateIsSignin',false)
    //
    //   commit('updateAgentstatus','0')
    //
    //   commit('updateSeatState','-2')
    // }
    }
};
