var DayCollectionStatistic = function () { return import('~/pages/report-manage/day-collection-statistic.vue'); };
var BehaviourControl = function () { return import('~/pages/report-manage/behaviour-control.vue'); };
var ClientBackMoney = function () { return import('~/pages/report-manage/client-back-money.vue'); };
var CollectorBackMoney = function () { return import('~/pages/report-manage/collector-back-money.vue'); };
export default [
    {
        path: '/report-manage/day-collection-statistic',
        name: 'day-collection-statistic',
        component: DayCollectionStatistic
    },
    {
        path: '/report-manage/behaviour-control',
        name: 'behaviour-control',
        component: BehaviourControl
    },
    {
        path: '/report-manage/client-back-money',
        name: 'client-back-money',
        component: ClientBackMoney
    },
    {
        path: '/report-manage/collector-back-money',
        name: 'collector-back-money',
        component: CollectorBackMoney
    }
];
