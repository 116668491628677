var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { WebSocketMsgService } from "~/services/common-service/websocket-msg.service";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { State, namespace } from "vuex-class";
import MessageDetail from "~/components/workspace/message/message-detail.vue";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { messageState } from "~/config/enum.config";
import { Prop, Watch } from "vue-property-decorator";
var workspaceModule = namespace("workspace");
var MessageBox = /** @class */ (function (_super) {
    __extends(MessageBox, _super);
    function MessageBox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.activeName = "UnRead";
        _this.unReadDataSet = [];
        _this.readDataSet = [];
        _this.unReadSelectionList = [];
        _this.readSelectionList = [];
        _this.currentMessage = "";
        _this.dialog = {
            messageDetail: false
        };
        _this.loading = {
            state: false
        };
        return _this;
    }
    MessageBox.prototype.onVisibleChange = function (value) {
        if (value) {
            // 判断当前选中的是已读还是未读选项卡
            if (this.activeName === "UnRead") {
                this.refreshUnReadData();
            }
            else {
                this.refreshReadData();
            }
        }
    };
    /**
     * 获取行样式
     */
    MessageBox.prototype.getRowClass = function (scope, index) {
        return scope.row.readStatus === messageState.unRead.value.toUpperCase()
            ? "unread"
            : "read";
    };
    /**
     * 刷新未读消息数据
     */
    MessageBox.prototype.refreshUnReadData = function () {
        var _this = this;
        this.loading.state = true;
        this.webSocketMsgService
            .getMsgList({ messageReadStatus: "UNREAD" }, this.unReadPageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.unReadDataSet = data;
            var type = data.map(function (x) { return x.messageType === "CASE_IMPORT"; });
            if (type.length > 0) {
                _this.updateMessageBoxVisible(true);
            }
        });
    };
    MessageBox.prototype.mounted = function () {
        this.refreshUnReadData();
    };
    /**
     * 刷新已读消息数据
     */
    MessageBox.prototype.refreshReadData = function () {
        var _this = this;
        this.loading.state = true;
        this.webSocketMsgService
            .getMsgList({ messageReadStatus: "READ" }, this.readPageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.readDataSet = data;
            var type = data.map(function (x) { return x.messageType === "CASE_IMPORT"; });
            if (type.length > 0) {
                _this.updateMessageBoxVisible(true);
            }
        });
    };
    /**
     * 打开消息详情
     */
    MessageBox.prototype.openMessageDetail = function (message) {
        var _this = this;
        this.dialog.messageDetail = true;
        this.$nextTick(function () {
            _this.currentMessage = message;
        });
    };
    /**
     * 批量删除消息
     */
    MessageBox.prototype.deleteMessages = function () {
        var _this = this;
        //this.loading.state = true;
        // 判断当前选中的是已读还是未读选项卡
        if (this.activeName === "UnRead") {
            if (!this.unReadSelectionList.length) {
                this.$message("请选择要删除的消息");
                return;
            }
            var messageIdList = this.unReadSelectionList.map(function (x) { return x.id; });
            this.webSocketMsgService.deletedReminderMessage(messageIdList, this.loading).subscribe(function () {
                _this.refreshUnReadData();
                _this.updateUnReadCount();
            }, function (_a) {
                var msg = _a.msg;
                return _this.$message.error(msg);
            });
        }
        else {
            if (!this.readSelectionList.length) {
                this.$message("请选择要删除的消息");
                return;
            }
            var messageIdList = this.readSelectionList.map(function (x) { return x.id; });
            this.webSocketMsgService.deletedReminderMessage(messageIdList, this.loading).subscribe(function () {
                _this.refreshReadData();
                _this.updateUnReadCount();
            }, function (_a) {
                var msg = _a.msg;
                return _this.$message.error(msg);
            });
        }
    };
    /**
     * tab切换事件
     */
    MessageBox.prototype.handleClick = function (tab) {
        // 重置分页参数
        this.readPageService.reset();
        this.unReadPageService.reset();
        // 重置选中项
        this.unReadSelectionList = [];
        this.readSelectionList = [];
        // 判断当前选中的是已读还是未读选项卡
        if (this.activeName === "UnRead") {
            this.refreshUnReadData();
        }
        else {
            this.refreshReadData();
        }
    };
    MessageBox.prototype.batchView = function () {
        var _this = this;
        if (this.unReadSelectionList.length === 0) {
            this.$alert('请选择查看消息', '提示', {
                confirmButtonText: '确定'
            });
            return;
        }
        var ids = this.unReadSelectionList.map(function (v) { return v.id; });
        this.webSocketMsgService.updateMsgStatus(ids).subscribe(function () {
            _this.refreshUnReadData();
            _this.updateUnReadCount();
        });
    };
    __decorate([
        Dependencies(WebSocketMsgService)
    ], MessageBox.prototype, "webSocketMsgService", void 0);
    __decorate([
        Dependencies(PageService)
    ], MessageBox.prototype, "readPageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], MessageBox.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], MessageBox.prototype, "unReadPageService", void 0);
    __decorate([
        workspaceModule.Action
    ], MessageBox.prototype, "updateUnReadCount", void 0);
    __decorate([
        workspaceModule.Mutation
    ], MessageBox.prototype, "updateMessageBoxVisible", void 0);
    __decorate([
        State
    ], MessageBox.prototype, "userData", void 0);
    __decorate([
        Prop()
    ], MessageBox.prototype, "visible", void 0);
    __decorate([
        Watch("visible")
    ], MessageBox.prototype, "onVisibleChange", null);
    MessageBox = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm,
                MessageDetail: MessageDetail
            }
        })
    ], MessageBox);
    return MessageBox;
}(Vue));
export default MessageBox;
