import { requestType } from '~/config/enum.config';
var SERVICE = 'domain-service';
var CONTROLLER = 'paymentRecordController';
export default {
    /**
     * PTP跳票
     */
    paymentPTPBouncedCheck: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'paymentPTPBouncedCheck',
        type: requestType.Get
    },
    /**
     * 查询CP记录
     */
    searchCPRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchCPRecord',
        type: requestType.Get
    },
    /**
    * 查询PTP记录
    */
    searchPTPRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchPTPRecord',
        type: requestType.Get
    },
    /**
     * 查询PTP记录
     */
    transferPTPToCP: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'transferPTPToCP',
        type: requestType.Post
    }
};
