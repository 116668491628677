import userController from "~/config/server/manage-service/user.controller";
import principalController from "~/config/server/manage-service/principal.controller";
import departmentController from "~/config/server/manage-service/department.controller";
import resourceController from "~/config/server/manage-service/resource.controller";
import colorController from "~/config/server/manage-service/color.controller";
import roleController from "~/config/server/manage-service/role.controller";
import userDeviceController from "~/config/server/manage-service/user-device.controller";
import sysParamController from "~/config/server/manage-service/sys-param.controller";
import systemLogController from "~/config/server/manage-service/system-log.controller";
import strategyController from "~/config/server/manage-service/strategy.controller";
import noticeController from "~/config/server/manage-service/notice.controller";
import statusConfigController from "~/config/server/manage-service/status-config.controller";
import templateController from "~/config/server/manage-service/template.controller";
import contactConfigController from "~/config/server/manage-service/contact-config.controller";
import sensitiveWordController from "~/config/server/manage-service/sensitive-word.controller";
export var manageService = {
    userController: userController,
    principalController: principalController,
    departmentController: departmentController,
    resourceController: resourceController,
    colorController: colorController,
    roleController: roleController,
    userDeviceController: userDeviceController,
    sysParamController: sysParamController,
    systemLogController: systemLogController,
    strategyController: strategyController,
    noticeController: noticeController,
    statusConfigController: statusConfigController,
    templateController: templateController,
    contactConfigController: contactConfigController,
    sensitiveWordController: sensitiveWordController
};
