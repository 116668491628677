import { requestType } from '~/config/enum.config';
var SERVICE = 'repair-service';
var CONTROLLER = 'alipayInfo';
export default {
    /**
     * 查询支付宝信息
     */
    search: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'search',
        type: requestType.Get
    },
    /**
     * 增加支付宝信息
     */
    insert: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'insert',
        type: requestType.Post
    },
    /**
     * 删除支付宝信息
     */
    delete: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'delete',
        type: requestType.Get
    },
    /**
     * 修改支付宝信息
     */
    update: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'update',
        type: requestType.Post
    }
};
