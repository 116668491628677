var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Emit } from 'vue-property-decorator';
import SvgIcon from '~/components/common/svg-icon.vue';
var DataForm = /** @class */ (function (_super) {
    __extends(DataForm, _super);
    function DataForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showCollapseIcon = true;
        _this.showCollapseContext = false;
        return _this;
    }
    DataForm.prototype.onRulesChange = function (val) { };
    DataForm.prototype.onSearch = function () { };
    DataForm.prototype.handleResetForm = function () { };
    DataForm.prototype.beforeCreate = function () {
        var inputSlot = this.$slots.inputs;
        if (!inputSlot)
            return;
    };
    /**
     * 初始化
     */
    DataForm.prototype.mounted = function () {
        this.inputs = this.$refs.inputs;
        this.setVisiableElement();
    };
    DataForm.prototype.setVisiableElement = function (showFlag) {
        if (showFlag === void 0) { showFlag = false; }
        if (!this.inputs.hasChildNodes()) {
            this.showCollapseIcon = false;
            return;
        }
        var elformItems = this.inputs.getElementsByClassName('el-form-item');
        if (!elformItems.length)
            return;
        if (!showFlag) {
            var el = this.$el;
            var itemCountInOneLine = Math.floor(el.offsetWidth / (elformItems[0].clientWidth + 5));
            var hiddenMaxCount = Math.abs(2 * itemCountInOneLine - 1);
            if (elformItems.length <= hiddenMaxCount) {
                this.showCollapseIcon = false;
                return;
            }
            for (var index = hiddenMaxCount; index < elformItems.length; index++) {
                var item = elformItems.item(index);
                if (!item.classList.contains('hidden-item'))
                    item.classList.add("hidden-item");
            }
        }
        else {
            for (var index = 0; index < elformItems.length; index++) {
                elformItems.item(index).classList.remove("hidden-item");
            }
        }
    };
    /**
     * 提交输入表单
     */
    DataForm.prototype.submitForm = function () {
        var _this = this;
        var dataForm = this.$refs['data-form'];
        dataForm.validate(function (success) {
            if (!success) {
                return;
            }
            if (_this.page && _this.page.pageIndex > 1) {
                _this.page.reset();
            }
            _this.onSearch();
        });
    };
    /**
     * 重置输入表单
     */
    DataForm.prototype.resetForm = function () {
        var dataForm = this.$refs['data-form'];
        dataForm.resetFields();
        this.handleResetForm();
    };
    /**
     * 折叠状态改变
     */
    DataForm.prototype.changeCollapseHandle = function () {
        this.showCollapseContext = !this.showCollapseContext;
        this.setVisiableElement(this.showCollapseContext);
    };
    __decorate([
        Prop()
    ], DataForm.prototype, "rules", void 0);
    __decorate([
        Prop()
    ], DataForm.prototype, "model", void 0);
    __decorate([
        Prop()
    ], DataForm.prototype, "page", void 0);
    __decorate([
        Prop({ type: Boolean, default: false })
    ], DataForm.prototype, "hiddenSearch", void 0);
    __decorate([
        Prop({ type: Boolean, default: false })
    ], DataForm.prototype, "hiddenReset", void 0);
    __decorate([
        Watch('rules')
    ], DataForm.prototype, "onRulesChange", null);
    __decorate([
        Emit('onSearch')
    ], DataForm.prototype, "onSearch", null);
    __decorate([
        Emit('handleResetForm') // 手动清空输入框
    ], DataForm.prototype, "handleResetForm", null);
    DataForm = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon,
            },
        })
    ], DataForm);
    return DataForm;
}(Vue));
export default DataForm;
