import { requestType } from '~/config/enum.config';
var SERVICE = 'common-service';
var CONTROLLER = 'dataDict';
export default {
    /**
     * 获取数据字典
     */
    getAll: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getAll',
        type: requestType.Get
    },
    getHashCode: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getHashCode',
        type: requestType.Get
    }
};
