import { requestType } from '~/config/enum.config';
var SERVICE = 'common-service';
var CONTROLLER = 'smaController';
export default {
    /**
     * 坐席登录
     */
    agentLogin: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'agentLogin',
        type: requestType.Get
    },
    /**
     * 坐席退出
     */
    agentLogout: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'agentLogout',
        type: requestType.Get
    },
    /**
     * 拨号
     */
    callOut: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'call',
        type: requestType.Get
    },
};
