import { requestType } from "~/config/enum.config";
var SERVICE = "dataimp-service";
var CONTROLLER = "exportAssistManagement";
export default {
    /**
     * 协调管理导出
     */
    exportAssistManagement: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportAssistManagement",
        type: requestType.Post,
    },
    /**
     * 导出调记
     */
    exportFolllowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportFolllowRecord",
        type: requestType.Post,
    },
    /**
     * 案件导出
     */
    exportInPoolCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportInPoolCase",
        type: requestType.Post,
    },
    /**
     * 案件导出2
     */
    exportHisCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportHisCase",
        type: requestType.Post,
    },
    /**
     * 导入调记
     */
    importExcelAssistManagementData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importExcelAssistManagementData",
        type: requestType.Post,
    },
    /**
     * 获取导出模板属性列表
     */
    getExportProperty: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getExportProperty",
        type: requestType.Get,
    },
    /**
     * 导出历史案件调记
     */
    exportHisCaseFolllowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportHisCaseFolllowRecord",
        type: requestType.Post,
    },
    /**
     * 导出调收电话
     */
    exportPersonalPhone: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportPersonalPhone",
        type: requestType.Post,
    },
    /**
     * 工单导出
     */
    exportOrderInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportOrderInfo",
        type: requestType.Post,
    },
    /**
     *获取批次号
     */
    getBatchNumbers: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getBatchNumbers",
        type: requestType.Get,
    },
    /**
     *补全调记
     */
    suppleCollRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "suppleCollRecord",
        type: requestType.Post,
    },
    /**
     *分案导出
     */
    exportCaseDisInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportCaseDisInfo",
        type: requestType.Post,
    },
};
