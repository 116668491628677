import { requestType } from '~/config/enum.config';
var SERVICE = 'repair-service';
var CONTROLLER = 'archiveData';
export default {
    /**
     * 查询计生资料
     */
    get: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'get',
        type: requestType.Get
    },
    detailList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'detailList',
        type: requestType.Get
    },
};
