var HistoricalQuery = function () { return import('~/pages/collection-manage/historical-query.vue'); };
var AllCase = function () { return import('~/pages/collection-manage/all-case.vue'); };
var PendingCollectionCase = function () { return import('~/pages/collection-manage/pending-collection-case.vue'); };
var CollectionCase = function () { return import('~/pages/collection-manage/collection-case.vue'); };
var DetentionCase = function () { return import('~/pages/collection-manage/detention-case.vue'); };
var CoUrgingCase = function () { return import('~/pages/collection-manage/co-urging-case.vue'); };
var PublicCase = function () { return import('~/pages/collection-manage/public-case.vue'); };
var FastQuery = function () { return import('~/pages/collection-manage/fast-query.vue'); };
var WithdrawalForm = function () { return import('~/pages/collection-manage/withdrawal-form.vue'); };
var PaymentCase = function () { return import('~/pages/collection-manage/payment-case.vue'); };
export default [
    {
        path: '/collection-manage/historical-query',
        name: 'historical-query',
        component: HistoricalQuery
    },
    {
        path: '/collection-manage/all-case/:principalId?',
        name: 'all-case',
        component: AllCase
    },
    {
        path: '/collection-manage/pending-collection-case/:principalId?',
        name: 'pending-collection-case',
        component: PendingCollectionCase
    },
    {
        path: '/collection-manage/collection-case/:principalId?',
        name: 'collection-case',
        component: CollectionCase
    },
    {
        path: '/collection-manage/detention-case/:principalId?',
        name: 'detention-case',
        component: DetentionCase
    },
    {
        path: '/collection-manage/co-urging-case/:principalId?',
        name: 'co-urging-case',
        component: CoUrgingCase
    },
    {
        path: '/collection-manage/public-case/:principalId?',
        name: 'public-case',
        component: PublicCase
    },
    {
        path: '/collection-manage/fast-query',
        name: 'fast-query',
        component: FastQuery
    },
    {
        path: '/collection-manage/withdrawal-form',
        name: 'withdrawal-form',
        component: WithdrawalForm
    },
    {
        path: '/collection-manage/payment-case/:principalId?',
        name: 'payment-case',
        component: PaymentCase
    }
];
