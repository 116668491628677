import { requestType } from "~/config/enum.config";
var SERVICE = "dataimp-service";
var CONTROLLER = "importOthersDataExcelRecordController";
export default {
    /**
     * 取消导入
     */
    cancelOthersExcelImpCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelOthersExcelImpCase",
        type: requestType.Delete,
    },
    /**
     * 确认导入
     */
    confirmOthersExcelImpCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "confirmOthersExcelImpCase",
        type: requestType.Delete,
    },
    /**
     * 查询导入结果
     */
    getImportOthersDataExcelList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getImportOthersDataExcelList",
        type: requestType.Get,
    },
    /**
     * 其他数据导入
     */
    importOthersExcelData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importOthersExcelData",
        type: requestType.Post,
    },
    /**
     * 案件对账导入详情
     */
    getImportBillDataList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getImportBillDataList",
        type: requestType.Get,
    },
    /**
     * 城市导入详情
     */
    getImportChangeCityList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getImportChangeCityList",
        type: requestType.Get,
    },
    /**
     * 案件停调导入详情
     */
    getImportEndList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getImportEndList",
        type: requestType.Get,
    },
    /**
     * 案件留案导入详情
     */
    getImportLeafList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getImportLeafList",
        type: requestType.Get,
    },
    /**
     * 委前调记导入详情
     */
    getImportPreRecordList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getImportPreRecordList",
        type: requestType.Get,
    },
    /**
     * 案件更新导入详情
     */
    getImportUpdateDataList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getImportUpdateDataList",
        type: requestType.Get,
    },
    /**
     * 案件警告信息导入详情
     */
    getImportWarnList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getImportWarnList",
        type: requestType.Get,
    },
    /**
     * 案件工单信息导入详情
     */
    getImportWorkOrderList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getImportWorkOrderList",
        type: requestType.Get,
    },
    /**
     * 补充材料导入详情
     */
    getImportCaseAnnexList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getImportCaseAnnexList",
        type: requestType.Get,
    },
    /**
     * 对账撤销
     */
    billRevoke: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "billRevoke",
        type: requestType.Get,
    },
    /**
     * 查询批次号
     */
    getBatchNumberByPrincipalId: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getBatchNumberByPrincipalId",
        type: requestType.Get,
    },
    /**
     * 对账撤销(单个记录)
     */
    revokeOneBill: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "revokeOneBill",
        type: requestType.Get,
    },
    /**
     * 对账撤销(单个记录)
     */
    importPauseCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importPauseCase",
        type: requestType.Get,
    },
    /**
     * 对账撤销(单个记录)
     */
    revokeOthersExcelImp: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "revokeOthersExcelImp",
        type: requestType.Get,
    },
};
