import { requestType } from "~/config/enum.config";
var SERVICE = "domain-service";
var CONTROLLER = "collectionCase";
export default {
    /**
     * 停调
     */
    stopCollectionCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "stopCollectionCase",
        type: requestType.Post,
    },
    /**
     * 修改记事本
     */
    updateComment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateComment",
        type: requestType.Post,
    },
    /**
     * 全部案件查询
     */
    queryAllCollectionCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryAllCollectionCase",
        type: requestType.Get,
    },
    /**
     * 执行页案件查询
     */
    caseCollectionSearch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "caseCollectionSearch",
        type: requestType.Get,
    },
    /**
     * 查询调收员调收的案件
     */
    caseCollectionStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "caseCollectionStatus",
        type: requestType.Get,
    },
    /**
     * 查询联系人
     */
    casePersonalContact: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "casePersonalContact",
        type: requestType.Get,
    },
    /**
     * 案件留案
     */
    leaveCollectionCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "leaveCollectionCase",
        type: requestType.Post,
    },
    /**
     * 查询
     */
    queryCollection: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryCollection",
        type: requestType.Get,
    },
    /**
     * 查询
     */
    queryCollectionByPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryCollectionByPrincipal",
        type: requestType.Get,
    },
    /**
     * 金额
     */
    queryCollectionAmtByPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryCollectionAmtByPrincipal",
        type: requestType.Get,
    },
    /**
     * 根据调收员或管理员组织查询相关的委托方
     */
    searchPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchPrincipal",
        type: requestType.Get,
    },
    /**
     * 查询不同委托方的全部案件
     */
    queryBatchNumberCollection: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryBatchNumberCollection",
        type: requestType.Get,
    },
    /**
     * 根据客户信息查询公债案件的数量
     */
    searchDebtCaseCount: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchDebtCaseCount",
        type: requestType.Get,
    },
    /**
     * 添加评语/批注/记事本
     */
    insertComment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "insertComment",
        type: requestType.Post,
    },
    /**
     * 查询评语/批注/记事本
     */
    searchComment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchComment",
        type: requestType.Get,
    },
    /**
     * 标记案件状态
     */
    signCaseState: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "signCaseState",
        type: requestType.Post,
    },
    /**
     * 删除案件状态
     */
    deleteCaseState: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteCaseState",
        type: requestType.Post,
    },
    /**
     * 案件查询
     */
    getCaseByState: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getCaseByState",
        type: requestType.Get,
    },
    /**
     * 个案查询
     */
    queryAdjustCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryAdjustCase",
        type: requestType.Get,
    },
    /**
     * 快速查询
     */
    quickSearch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "quickSearch",
        type: requestType.Get,
    },
    /**
     * 历史查询
     */
    getHisCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getHisCase",
        type: requestType.Get,
    },
    /**
     * 案件统计
     */
    queryCollectionCount: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryCollectionCount",
        type: requestType.Get,
    },
    backCollectionCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "backCollectionCase",
        type: requestType.Post,
    },
    /**
     * 删除案件
     */
    deleteCaseByIds: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteCaseByIds",
        type: requestType.Post,
    },
    /**
     * 批次删除
     */
    deleteCaseByBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteCaseByBatchNumber",
        type: requestType.Get,
    },
    /**
     * 多维定位
     */
    multidimensionalPositioning: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "multidimensionalPositioning",
        type: requestType.Get,
    },
    /**
     * 查询记事本
     */
    searchCaseComment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchCaseComment",
        type: requestType.Get,
    },
    /**
     * 根据委托方获取批次列表
     */
    getBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getBatchNumber",
        type: requestType.Get,
    },
    /**
     * 个案调整根据委托方获取批次列表
     */
    queryAdjustCaseBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryAdjustCaseBatchNumber",
        type: requestType.Get,
    },
    /**
     * 退案案件池 根据委托方获取批次列表
     */
    getHisBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getHisBatchNumber",
        type: requestType.Get,
    },
    /**
     * 批量永久删除
     */
    deleteBaseCaseForever: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteBaseCaseForever",
        type: requestType.Post,
    },
    /**
     * 查询共债批次
     */
    searchDebtBatch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchDebtBatch",
        type: requestType.Get,
    },
    /**
     * 查询共债案件
     */
    searchDebtCardInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchDebtCardInfo",
        type: requestType.Get,
    },
    /**
     * 查询卡信息
     */
    searchOneCardInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchOneCardInfo",
        type: requestType.Get,
    },
    /**
     * 删除评语
     */
    deleteComment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteComment",
        type: requestType.Get,
    },
    /**
     * 刷新退案时间
     */
    changeCaseEndCaseDateByPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "changeCaseEndCaseDateByPrincipal",
        type: requestType.Post,
    },
    /**
     * 快速调收查询欠款总额和委案总额
     */
    quickSearchAmt: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "quickSearchAmt",
        type: requestType.Get,
    },
    /**
     * 个案调整查询欠款总额和委案总额
     */
    queryAdjustCaseAmt: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryAdjustCaseAmt",
        type: requestType.Get,
    },
    /**
     * 全部案件查询欠款总额和委案总额
     */
    queryAllCollectionCaseAmt: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryAllCollectionCaseAmt",
        type: requestType.Get,
    },
};
