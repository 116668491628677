import { requestType } from "~/config/enum.config";
var SERVICE = "domain-service";
var CONTROLLER = "caseFollowController";
export default {
    /**
     * 查询查找记录
     */
    getFindRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getFindRecord",
        type: requestType.Get,
    },
    /**
     * 查询调记
     */
    getFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getFollowRecord",
        type: requestType.Get,
    },
    /**
     * 查询跟进记录统计
     */
    getFollowRecordCount: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getFollowRecordCount",
        type: requestType.Get,
    },
    /**
     * 查询其他跟进记录
     */
    getOtherFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getOtherFollowRecord",
        type: requestType.Get,
    },
    /**
     * 查询委前跟进记录
     */
    getPreFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getPreFollowRecord",
        type: requestType.Get,
    },
    /**
     * 添加查找记录
     */
    saveFindRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "saveFindRecord",
        type: requestType.Post,
    },
    /**
     * 添加调记
     */
    saveFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "saveFollowRecord",
        type: requestType.Post,
    },
    /**
     * 添加其他跟进记录
     */
    saveOtherFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "saveOtherFollowRecord",
        type: requestType.Post,
    },
    /**
     * 根据电话号码查询联系人调记
     */
    searchPersonalFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchPersonalFollowRecord",
        type: requestType.Get,
    },
    /**
     *
     */
    updateFollowContent: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateFollowContent",
        type: requestType.Post,
    },
    /**
     * 检测调记敏感词
     */
    checkFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "checkFollowRecord",
        type: requestType.Post,
    },
    /**
     * 工单回复
     */
    replayOtherFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "replayOtherFollowRecord",
        type: requestType.Post,
    },
    /**
     * 历史跟进记录
     */
    getHisFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getHisFollowRecord",
        type: requestType.Get,
    },
    /**
     * 删除调记
     */
    deletedFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deletedFollowRecord",
        type: requestType.Get,
    },
    /**
     * 修改调记
     */
    updateFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateFollowRecord",
        type: requestType.Post,
    },
    /**
     * 共债信息在案 历史信息查询
     */
    getDebtFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getDebtFollowRecord",
        type: requestType.Get,
    },
    /**
     * 保存外访调记
     */
    saveVisistFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "saveVisistFollowRecord",
        type: requestType.Post,
    },
};
