import { requestType } from '~/config/enum.config';
var SERVICE = 'domain-service';
var CONTROLLER = 'personal';
export default {
    /**
     * 创建联系人地址
     */
    inertPersonalAddress: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'inertPersonalAddress',
        type: requestType.Post
    },
    /**
     * 修改联系人地址
     */
    modifyPersonalAddress: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyPersonalAddress',
        type: requestType.Post
    },
    /**
     * 删除联系人地址
     */
    deleteAddressContact: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteAddressContact',
        type: requestType.Get
    },
    /**
     * 创建联系人
     */
    inertPersonalContact: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'inertPersonalContact',
        type: requestType.Post
    },
    /**
     * 修改联系人
     */
    modifyPersonalContact: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyPersonalContact',
        type: requestType.Post
    },
    /**
     * 联系人地址查询
     */
    searchPersonalAddress: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchPersonalAddress',
        type: requestType.Get
    },
    /**
     * 联系人电话查询
     */
    searchPersonalContact: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchPersonalContact',
        type: requestType.Get
    },
    /**
     * 删除联系人
     */
    deletePersonalContact: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deletePersonalContact',
        type: requestType.Get
    },
    /**
     * 电话或地址状态更新
     */
    markPhoneOrAddressStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'markPhoneOrAddressStatus',
        type: requestType.Post
    },
    /**
   * 修改联系人禁用状态
   */
    modifyBanState: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyBanState',
        type: requestType.Post
    },
};
