import { requestType } from '~/config/enum.config';
var SERVICE = '';
var CONTROLLER = '';
export default {
    /**
     * 开始电话呼叫
     */
    getHistoryList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getHistoryList',
        type: requestType.Get
    },
    /**
     * 开始电话呼叫
     */
    getFriendHistoryIMRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getFriendHistoryIMRecord',
        type: requestType.Get
    },
    /**
     * 开始电话呼叫
     */
    getHistoryIMUserList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getHistoryIMUserList',
        type: requestType.Get
    },
};
