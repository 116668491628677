import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'importDataExcelRecordController';
export default {
    /**
     * 获取未确认的批次号
     */
    getImportDataExcelBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getImportDataExcelBatchNumber',
        type: requestType.Get
    },
    /**
     * 查询导入结果
     */
    getImportDataExcelList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getImportDataExcelList',
        type: requestType.Get
    },
    /**
     * 获取未导入前的案件详情
     */
    getImportDataExcelCaseDetail: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getImportDataExcelCaseDetail',
        type: requestType.Get
    },
    /**
     * 确认导入
     */
    confirmExcelImpCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'confirmExcelImpCase',
        type: requestType.Put
    },
    /**
     * 获取合并
     */
    getMergeDataList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getMergeDataList',
        type: requestType.Get
    }
};
