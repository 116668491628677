var CollectionExecution = function () { return import('~/pages/acc-manage/collection-execution.vue'); };
var RepayInfo = function () { return import('~/pages/acc-manage/repay-info.vue'); };
export default [
    {
        path: '/acc-manage/collection-execution/:caseId',
        name: 'collection-execution',
        component: CollectionExecution
    },
    {
        path: '/acc-manage/repay-info',
        name: 'repay-info',
        component: RepayInfo
    }
];
