import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "resourceController";
export default {
    /**
     * 查询全部资源
     */
    findAllResource: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllResource",
        type: requestType.Get
    }
};
