import store from '~/store';
var DictService = /** @class */ (function () {
    function DictService() {
    }
    /**
     * 获取字典数据
     * @param codes
     */
    DictService.getDictData = function () {
        var keys = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            keys[_i] = arguments[_i];
        }
        var results = [];
        // 获取字典项
        keys.forEach(function (key) {
            var items = store.state.dictData[key];
            if (items && items.length) {
                results.push.apply(results, items);
            }
        });
        return results;
    };
    /**
     * 过滤器：获取字典名称
     * @param code 字典键值
     */
    DictService.getDictName = function (code, key) {
        var result = '';
        if (code && key) {
            var values = store.state.dictData[key];
            if (!values)
                return key;
            var find = values.find(function (x) { return x.code === code; }) || {};
            result = find.name;
        }
        return result;
    };
    /**
     * 根据字典值和分类获取字典的键名称
     * @param value 字典值
     * @param key 字典分类
     */
    DictService.getDictKeyStr = function (value, key) {
        var result = '';
        if (value && key) {
            var values = store.state.dictData[key];
            var find = values.find(function (x) { return x.name === value; }) || {};
            result = find.code;
        }
        return result;
    };
    return DictService;
}());
export { DictService };
