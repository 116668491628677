import { requestType } from '~/config/enum.config';
var SERVICE = 'domain-service';
var CONTROLLER = 'caseTransferLogController';
export default {
    /**
     * 日志查询
     */
    searchCaseTransferLog: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchCaseTransferLog',
        type: requestType.Get
    }
};
