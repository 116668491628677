import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'dataExcelCaseController';
export default {
    /**
     * 获取Excel导入数据的表头
     */
    getExcelImportTitle: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getExcelImportTitle',
        type: requestType.Get
    },
    /**
   * 获取Excel映射字段
   */
    getExcelList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getExcelList',
        type: requestType.Get
    }
};
