var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { State, Action } from "vuex-class";
import WorkPassword from "~/components/workspace/work-password.vue";
import { UserService } from "~/services/manage-service/user.service";
var WorkUser = /** @class */ (function (_super) {
    __extends(WorkUser, _super);
    function WorkUser() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dialog = {
            workPassword: false,
            callPhone: false
        };
        return _this;
    }
    WorkUser.prototype.cancel = function () {
        this.dialog.workPassword = false;
    };
    WorkUser.prototype.mounted = function () {
        this.listenClose();
    };
    WorkUser.prototype.signOut = function () {
        this.userService.logout().subscribe(function (data) { });
    };
    /**
     *  用户操作处理
     */
    WorkUser.prototype.dropdownClickHandle = function (command) {
        switch (command) {
            case "exit":
                this.loginOut();
                break;
            case "password":
                this.dialog.workPassword = true;
                break;
        }
    };
    WorkUser.prototype.listenClose = function () {
        var that = this;
        var _beforeUnload_time = 0, _gap_time = 0;
        window.onbeforeunload = function (event) {
            _beforeUnload_time = new Date().getTime();
        };
        window.onunload = function (event) {
            _gap_time = new Date().getTime() - _beforeUnload_time;
            if (_gap_time <= 5) {
                // 浏览器关闭
                that.signOut();
            }
            else {
                // 浏览器刷新
            }
        };
    };
    WorkUser.prototype.loginOnce = function () {
        this.dialog.workPassword = false;
        this.loginOut();
    };
    WorkUser.prototype.loginOut = function () {
        this.$router.push("/");
        this.clearUserLoginData();
    };
    __decorate([
        State
    ], WorkUser.prototype, "userData", void 0);
    __decorate([
        Action
    ], WorkUser.prototype, "clearUserLoginData", void 0);
    __decorate([
        Dependencies(UserService)
    ], WorkUser.prototype, "userService", void 0);
    WorkUser = __decorate([
        Component({
            components: {
                WorkPassword: WorkPassword
            }
        })
    ], WorkUser);
    return WorkUser;
}(Vue));
export default WorkUser;
