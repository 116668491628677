var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import MessageBox from "~/components/workspace/message/message-box.vue";
import SvgIcon from "~/components/common/svg-icon.vue";
import { Dependencies } from "~/core/decorator";
import { ReminderMessageService } from "~/services/reminder-service/reminder-message.service";
var workspaceModule = namespace("workspace");
var WorkMessage = /** @class */ (function (_super) {
    __extends(WorkMessage, _super);
    function WorkMessage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(WorkMessage.prototype, "messageBoxVisible", {
        get: function () {
            return this._messageBoxVisible;
        },
        set: function (value) {
            this.updateMessageBoxVisible(value);
        },
        enumerable: false,
        configurable: true
    });
    WorkMessage.prototype.mounted = function () {
        var _this = this;
        // 更新未读消息
        this.updateUnReadCount();
        // 收到消息通知时更新未读消息
        this.$reminder
            .addMessageListener()
            .subscribe(function (data) {
            _this.updateUnReadCount();
        });
    };
    __decorate([
        Dependencies(ReminderMessageService)
    ], WorkMessage.prototype, "reminderMessageService", void 0);
    __decorate([
        workspaceModule.State
    ], WorkMessage.prototype, "unReadCount", void 0);
    __decorate([
        workspaceModule.State("messageBoxVisible")
    ], WorkMessage.prototype, "_messageBoxVisible", void 0);
    __decorate([
        workspaceModule.Mutation
    ], WorkMessage.prototype, "updateMessageBoxVisible", void 0);
    __decorate([
        workspaceModule.Action
    ], WorkMessage.prototype, "updateUnReadCount", void 0);
    WorkMessage = __decorate([
        Component({
            components: {
                MessageBox: MessageBox,
                SvgIcon: SvgIcon
            }
        })
    ], WorkMessage);
    return WorkMessage;
}(Vue));
export default WorkMessage;
