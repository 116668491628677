// const sortType = {
//   "ascending": "asc",
//   "descending": "desc"
// }
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var SortService = /** @class */ (function () {
    function SortService() {
        this.sort = {};
        this.sortType = {
            "ascending": "asc",
            "descending": "desc"
        };
    }
    /**
     * 转换排序对象为字符串
     */
    SortService.stringify = function (value) {
        if (typeof value !== "object") {
            return "";
        }
        if (value instanceof SortService) {
            value = value.sort;
        }
        return Object.entries(value).map(function (_a) {
            var k = _a[0], v = _a[1];
            return "sort=" + k + "," + v;
        }).join('&');
    };
    /**
     * 更新分页配置信息
     * @param param
     */
    SortService.prototype.update = function (key, value) {
        var _a;
        if (key == null || value == null) {
            return this.reset();
        }
        this.sort = (_a = {},
            _a[key] = this.sortType[value] || value,
            _a);
    };
    SortService.prototype.clone = function () {
        return __assign({}, this.sort);
    };
    /**
     * 重置分页数据
     */
    SortService.prototype.reset = function () {
        this.sort = {};
    };
    return SortService;
}());
export { SortService };
