import { requestType } from '~/config/enum.config';
var SERVICE = 'repair-service';
var CONTROLLER = 'specialTransferData';
export default {
    /**
     * 查询特调资料
     */
    search: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'search',
        type: requestType.Get
    },
    /**
     * 增加特调资料
     */
    insert: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'insert',
        type: requestType.Post
    },
    /**
     * 删除特调资料
     */
    delete: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'delete',
        type: requestType.Delete
    },
    /**
     * 修改特调资料
     */
    update: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'update',
        type: requestType.Post
    },
    /**
     * 添加特调资料凭证
     */
    addCredential: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addCredential',
        type: requestType.Post
    },
    /**
     * 根据用户证件号获取图片明细信息
     */
    searchDetails: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchDetails',
        type: requestType.Get
    },
    /**
     * 按导入批次删除户籍资料
     */
    deleteByBatch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteByBatch',
        type: requestType.Get
    }
};
