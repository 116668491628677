import { requestType } from '~/config/enum.config';
var SERVICE = 'management-service';
var CONTROLLER = 'complianceConfig';
export default {
    /**
    * 启用/禁用 配置
    */
    changeState: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "changeState",
        type: requestType.Put
    },
    /**
     * 添加合规检查配置
     */
    createComplianceConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "createComplianceConfig",
        type: requestType.Post
    },
    /**
     * 删除合规检查配置
     */
    delete: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "delete",
        type: requestType.Delete
    },
    /**
     * 隐藏资源
     */
    hiddenResources: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "hiddenResources",
        type: requestType.Put
    },
    /**
     * 修改允许的委托方
     */
    modifyAllowedPrincipals: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "modifyAllowedPrincipals",
        type: requestType.Put
    },
    /**
     * 修改合规检查配置
     */
    modifyComplianceConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "modifyComplianceConfig",
        type: requestType.Post
    },
    /**
     * 查询所有合规检查配置
     */
    query: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "query",
        type: requestType.Get
    }
};
