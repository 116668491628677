import { render, staticRenderFns } from "./task-box.vue?vue&type=template&id=57fde927"
import script from "./task-box.vue?vue&type=script&lang=ts"
export * from "./task-box.vue?vue&type=script&lang=ts"
import style0 from "./task-box.vue?vue&type=style&index=0&id=57fde927&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports