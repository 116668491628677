import kosekiDataController from '~/config/server/repair-service/koseki-data.controller';
import kosekiRemarkController from '~/config/server/repair-service/koseki-remark.controller';
import villageCommitteeDataController from '~/config/server/repair-service/village-committee-data.controller';
import socialSecurityDataController from '~/config/server/repair-service/social-security-data.controller';
import relationshipController from '~/config/server/repair-service/relationship.controller';
import communicationDataController from '~/config/server/repair-service/communication-data.controller';
import familyPlanningDataController from '~/config/server/repair-service/family-planning-data.controller';
import specialTransferDataController from '~/config/server/repair-service/special-transfer-data.controller';
import alipayInfoController from '~/config/server/repair-service/alipayInfo.controller';
import archiveDataController from '~/config/server/repair-service/archive-data.controller';
import dataRepairImportController from '~/config/server/repair-service/data-repair-import.controller';
import phoneNumberController from '~/config/server/repair-service/phone-number.controller';
import idCardController from '~/config/server/repair-service/idCard.controller';
import areaController from '~/config/server/repair-service/area.controller';
export var repairService = {
    kosekiDataController: kosekiDataController,
    kosekiRemarkController: kosekiRemarkController,
    villageCommitteeDataController: villageCommitteeDataController,
    socialSecurityDataController: socialSecurityDataController,
    relationshipController: relationshipController,
    communicationDataController: communicationDataController,
    familyPlanningDataController: familyPlanningDataController,
    specialTransferDataController: specialTransferDataController,
    alipayInfoController: alipayInfoController,
    archiveDataController: archiveDataController,
    dataRepairImportController: dataRepairImportController,
    phoneNumberController: phoneNumberController,
    idCardController: idCardController,
    areaController: areaController
};
