import { requestType } from '~/config/enum.config';
var SERVICE = 'common-service';
var CONTROLLER = 'beauPhoneController';
export default {
    /**
     * 开始呼叫电话
     */
    call: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'call',
        type: requestType.Post
    }
};
