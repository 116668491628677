import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "roleController";
export default {
    /**
     * 新增角色
     */
    addRole: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addRole',
        type: requestType.Post
    },
    /**
     * 修改角色
     */
    modifyRole: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyRole',
        type: requestType.Put
    },
    /**
     * 删除角色
     */
    deleteRole: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteRole',
        type: requestType.Delete
    },
    /**
     * 用户分配角色
     */
    distributeRole: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'distributeRole',
        type: requestType.Post
    },
    /**
     * 删除角色下用户
     */
    deleteRoleUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteRoleUser',
        type: requestType.Put
    },
    /**
     * 分配模块权限
     */
    distributePermission: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'distributePermission',
        type: requestType.Post
    },
    /**
     * 复制角色
     */
    copyRole: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'copyRole',
        type: requestType.Get
    },
    /**
     * 多条件查询角色
     */
    findAllRole: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllRole',
        type: requestType.Get
    },
    /**
     * 多条件查询角色下用户
     */
    findUserByRole: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findUserByRole',
        type: requestType.Get
    },
    /**
     * 查询角色下所有资源
     */
    findAllResource: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllResource',
        type: requestType.Get
    },
    /**
     * 查询角色下所有控件
     */
    findAllControls: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllControls',
        type: requestType.Get
    },
    /**
     * 查询所有角色
     */
    getAllRoles: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getAllRoles',
        type: requestType.Get
    },
    /**
     * 批量分配角色
     */
    batchDistributeRole: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'batchDistributeRole',
        type: requestType.Post
    },
    /**
     * 查询所有角色
     */
    findRoles: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findRoles',
        type: requestType.Get
    }
};
