export default {
    namespaced: true,
    state: {
        currentUser: ''
    },
    mutations: {
        updateCurrentUser: function (state, user) {
            state.currentUser = user;
        }
    },
    actions: {}
};
