import cityPlugin from '~/extension/plugin/city.plugin';
import dictPlugin from '~/extension/plugin/dict.plugin';
import filterPlugin from '~/extension/plugin/filter.plugin';
import helperPlugin from '~/extension/plugin/helper.plugin';
import validatorPlugin from '~/extension/plugin/validator.plugin';
import reminderPlugin from '~/extension/plugin/reminder.plugin';
import coordinatePlugin from '~/extension/plugin/coordinate.plugin';
import commonPlugin from "~/extension/plugin/common.plugin";
export default (function (_a) {
    var store = _a.store;
    return ({
        cityPlugin: cityPlugin,
        dictPlugin: dictPlugin,
        filterPlugin: filterPlugin,
        helperPlugin: helperPlugin,
        validatorPlugin: validatorPlugin,
        reminderPlugin: reminderPlugin,
        coordinatePlugin: coordinatePlugin,
        commonPlugin: commonPlugin
    });
});
