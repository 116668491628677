import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'exportTemplateController';
export default {
    /**
     * 多条件查询导出模板
     */
    findAllExportTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllExportTemplate',
        type: requestType.Get
    },
    /**
     * 获取导出字段
     */
    getExportProperty: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getExportProperty',
        type: requestType.Get
    },
    /**
     * 新增导出模板
     */
    addExportTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addExportTemplate',
        type: requestType.Post
    },
    /**
     * 删除导出模板
     */
    deleteExportTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteExportTemplate',
        type: requestType.Delete
    },
    /**
     * 获取导出模板信息
     */
    getExportTemplateById: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getExportTemplateById',
        type: requestType.Get
    },
    /**
     * 修改导出模板
     */
    modifyExportTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyExportTemplate',
        type: requestType.Put
    },
    /**
     * 查询委托方下导出模板
     */
    getExportTemplateByPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getExportTemplateByPrincipal',
        type: requestType.Get
    },
    /**
     * 解析Excel数据模板头信息
     */
    checkExportTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'checkExportTemplate',
        type: requestType.Post
    }
};
