var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { TaskBoxService } from "~/services/common-service/task-box.service";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { namespace } from "vuex-class";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { Prop, Watch } from "vue-property-decorator";
import { UploadFileService } from "~/services/common-service/upload-file.service";
var workspaceModule = namespace("workspace");
var TaskBox = /** @class */ (function (_super) {
    __extends(TaskBox, _super);
    function TaskBox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.selectionList = [];
        _this.taskDataSet = [];
        _this.currentMessage = "";
        return _this;
    }
    TaskBox.prototype.onVisibleChange = function (value) {
        if (value) {
            this.refreshData();
        }
    };
    /**
     * 毫秒转时分秒
     */
    TaskBox.prototype.getTime = function (end, start) {
        try {
            var date3 = new Date(end).getTime() - new Date(start).getTime(); //时间差的毫秒数
            //计算出相差天数
            var days = Math.floor(date3 / (24 * 3600 * 1000));
            //计算出小时数
            var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
            var hours = Math.floor(leave1 / (3600 * 1000));
            //计算相差分钟数
            var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
            var minutes = Math.floor(leave2 / (60 * 1000));
            //计算相差秒数
            var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
            var seconds = Math.round(leave3 / 1000);
            return days + "\u5929" + hours + "\u65F6" + minutes + "\u5206" + seconds + "\u79D2";
        }
        catch (ex) { }
    };
    /**
     * 刷新数据
     */
    TaskBox.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.taskBoxService
            .queryTaskBox(this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.taskDataSet = data;
        });
    };
    TaskBox.prototype.downloadFile = function (row) {
        var _this = this;
        this.uploadFileService.getFileStreamById(row.fileId).subscribe(function (name) {
            _this.$message.success("下载成功");
        });
    };
    /**
     * 清除定时器
     */
    TaskBox.prototype.resetInterval = function () {
        window.clearInterval(this.interval);
    };
    TaskBox.prototype.start = function () {
        var _this = this;
        this.interval = setInterval(function () {
            _this.taskDataSet.forEach(function (x) {
                if (!x.endDate) {
                    x.flag = true;
                }
                if (x.flag) {
                    x.endDate = Date.now();
                }
            });
        }, 5000);
    };
    /**
     * 初始化
     */
    TaskBox.prototype.mounted = function () {
        this.refreshData();
        this.start();
    };
    TaskBox.prototype.deleteTasks = function () {
        var _this = this;
        if (!this.selectionList.length) {
            this.$message("请选择要删除的消息");
            return;
        }
        this.loading.state = true;
        var taskBoxIdList = this.selectionList.map(function (x) { return x.id; });
        this.taskBoxService.deletedTaskBox(taskBoxIdList, this.loading).subscribe(function (data) {
            _this.refreshData();
        }, function (_a) {
            var msg = _a.msg;
            return _this.$message.error(msg);
        });
    };
    __decorate([
        Dependencies(PageService)
    ], TaskBox.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], TaskBox.prototype, "sortService", void 0);
    __decorate([
        Dependencies(TaskBoxService)
    ], TaskBox.prototype, "taskBoxService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], TaskBox.prototype, "uploadFileService", void 0);
    __decorate([
        workspaceModule.Action
    ], TaskBox.prototype, "updateUnReadCount", void 0);
    __decorate([
        Prop()
    ], TaskBox.prototype, "visible", void 0);
    __decorate([
        Watch("visible")
    ], TaskBox.prototype, "onVisibleChange", null);
    TaskBox = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm
            }
        })
    ], TaskBox);
    return TaskBox;
}(Vue));
export default TaskBox;
