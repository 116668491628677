import { requestType } from '~/config/enum.config';
var SERVICE = 'business-service';
var CONTROLLER = 'paymentCheckController';
export default {
    /**
     * 查询ptp记录
     */
    queryPtpRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryPtpRecord',
        type: requestType.Get
    },
    /**
     * 查询cp记录
     */
    queryCpRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryCpRecord',
        type: requestType.Get
    },
    /**
     * 跳票
     */
    bouncedCheck: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'bouncedCheck',
        type: requestType.Post
    },
    /**
     * 转cp
     */
    changeStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'changeStatus',
        type: requestType.Post
    },
    /**
     * 还款核查回复
     */
    queryReplyRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryReplyRecord',
        type: requestType.Get
    },
    /**
     * cp记录回复
     */
    replyPaymentCheck: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'replyPaymentCheck',
        type: requestType.Post
    }
};
