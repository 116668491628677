import { requestType } from "~/config/enum.config";
var SERVICE = "domain-service";
var CONTROLLER = "caseApplyOperate";
export default {
    /**
     * 协调管理模块中，管理页面的，审批查询
     */
    caseApprovalSearch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "caseApprovalSearch",
        type: requestType.Get,
    },
    /**
     * 协调管理模块中，管理页面的，审批操作
     */
    caseApplyApprove: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "caseApplyApprove",
        type: requestType.Post,
    },
    /**
     * 申请操作
     */
    caseApplyOperated: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "caseApplyOperated",
        type: requestType.Post,
    },
    /**
     * 协调审批查询
     */
    assistCaseApproveSearch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "assistCaseApproveSearch",
        type: requestType.Get,
    },
    /**
     * 协调申请操作
     */
    assistApply: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "assistApply",
        type: requestType.Post,
    },
    /**
     * 本地协调审批
     */
    localApplyApprove: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "localApplyApprove",
        type: requestType.Post,
    },
    /**
     * 协调申请记录查询
     */
    assistApplySearch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "assistApplySearch",
        type: requestType.Get,
    },
    /**
     * 申请记录查询
     */
    caseApplySearch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "caseApplySearch",
        type: requestType.Get,
    },
    /**
     * 异地协调管理审批操作
     */
    assistApplyApprove: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "assistApplyApprove",
        type: requestType.Post,
    },
    /**
     * 检查资料是否已申请
     */
    validCheckMaterialApply: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "validCheckMaterialApply",
        type: requestType.Post,
    },
    /**
     * 批量单卡导出
     */
    BatchExport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "BatchExport",
        type: requestType.Post,
    },
    /**
     * 批量多卡导出Excel
     */
    BatchExportExcel: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "BatchExportExcel",
        type: requestType.Post,
    },
};
