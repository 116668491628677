import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "contactConfigController";
export default {
    /**
     * 查询联络配置树结构
     */
    getContactConfigTree: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getContactConfigTree",
        type: requestType.Get,
    },
    /**
     * 获取子集
     */
    getChildren: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getChildren",
        type: requestType.Get,
    },
    /**
     * 根据ID获取配置信息
     */
    getContactConfigById: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getContactConfigById",
        type: requestType.Get,
    },
    /**
     * 新增联络配置
     */
    addContactConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addContactConfig",
        type: requestType.Post,
    },
    /**
     * 修改联络配置
     */
    modifyContactConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "modifyContactConfig",
        type: requestType.Put,
    },
    /**
     * 删除联络配置
     */
    deleteContactConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteContactConfig",
        type: requestType.Delete,
    },
    /**
     * 查询没有联络配置的委托方
     */
    getPrincipalNoConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getPrincipalNoConfig",
        type: requestType.Get,
    },
    /**
     * 查询跟进记录字段项
     */
    getFollowMarchRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getFollowMarchRecord",
        type: requestType.Get,
    },
    /**
     * 查询跟进记录映射值
     */
    getAllContactConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllContactConfig",
        type: requestType.Get,
    },
    /**
     * 查询调记匹配字段
     */
    getFollowRecordFields: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getFollowRecordFields",
        type: requestType.Get,
    },
    /**
     * 查询联系人关系
     */
    getRelationShip: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getRelationShip",
        type: requestType.Get,
    },
    /**
     * 查询地址状态
     */
    getAddressStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAddressStatus",
        type: requestType.Get,
    },
    /**
     * 查询地址类型
     */
    getAddressType: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAddressType",
        type: requestType.Get,
    },
    /**
     * 查询联络结果
     */
    getContactResult: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getContactResult",
        type: requestType.Get,
    },
    /**
     * 联络结果移动
     */
    moveContactConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "moveContactConfig",
        type: requestType.Post,
    },
};
