import store from '~/store';
import * as enumData from '~/config/enum.config';
import { DictService } from "~/utils/dict.service";
import { LodashService } from '~/utils/lodash.service';
import { differenceInCalendarDays } from "date-fns";
var FilterService = /** @class */ (function () {
    function FilterService() {
    }
    /**
     * 转换枚举数据
     * @param value
     * @param key
     */
    FilterService.enumConvert = function (value, key) {
        // 排除空字典或者空key
        if (!enumData || !key || !enumData[key]) {
            return '';
        }
        var data = enumData[key] instanceof Array ? enumData[key] : Object.values(enumData[key]);
        var target = data.find(function (x) { return x.value === value; });
        return target ? target.name : "";
    };
    /**
     * 转换字典数据
     * @param 字典code
     */
    FilterService.dictConvert = function (code, key) {
        if (!code || !key) {
            return "";
        }
        return DictService.getDictName(code, key);
    };
    /**
     * 日期范围转换
     * @param dateRange
     * @param fmt
     */
    FilterService.dateRanageFormat = function (dateRange, fmt) {
        if (fmt === void 0) { fmt = "yyyy-MM-dd hh:mm:ss"; }
        var target = {
            start: '',
            end: ''
        };
        // 检测非法输入
        if (!dateRange || dateRange.length === 0 || !(dateRange instanceof Array)) {
            return target;
        }
        target.start = FilterService.dateFormat(dateRange[0], fmt);
        target.end = FilterService.dateFormat(dateRange[1], fmt);
        return target;
    };
    /**
     * 日期时间格式化
     * @param date
     * @param fmt 默认值为长日期格式
     */
    FilterService.dateTimeFormat = function (date, fmt) {
        if (fmt === void 0) { fmt = "yyyy-MM-dd hh:mm:ss"; }
        return FilterService.dateFormat(date, fmt);
    };
    /**
     * 当前日期减去返回日期
     */
    FilterService.timeInterval = function (date, fmt) {
        if (fmt === void 0) { fmt = "yyyy-MM-dd"; }
        if (!date)
            return;
        var nowDate = new Date();
        var backDate = new Date(FilterService.dateFormat(date, fmt).replace(/-/g, "/"));
        var intervalDay = nowDate - backDate;
        return Math.ceil(intervalDay / (1000 * 60 * 60 * 24));
    };
    /**
     * 日期格式化
     * @param date
     * @param fmt 默认值为短日期格式
     */
    FilterService.dateFormat = function (date, fmt) {
        if (fmt === void 0) { fmt = "yyyy-MM-dd"; }
        // 空数据处理
        if (date === null || date === undefined || date === '') {
            return '';
        }
        // 如果是时间戳则转化为时间
        if (typeof date === 'number') {
            date = new Date(date);
        }
        var o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'h+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds(),
            'q+': Math.floor((date.getMonth() + 3) / 3),
            'S': date.getMilliseconds() // 毫秒
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        for (var k in o) {
            if (new RegExp('(' + k + ')').test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
        }
        return fmt;
    };
    /**
     * 千分位转换
     * @param number
     */
    FilterService.toThousands = function (number, fixed) {
        if (fixed === void 0) { fixed = 2; }
        var num = '';
        if (number === null || number === '') {
            num = number;
        }
        else {
            num = Number(number).toFixed(fixed);
            if (isNaN(num) || num === '' || num === undefined || num === null) {
                return '';
            }
            num = num + '';
            if (/^.*\..*$/.test(num)) {
                var pointIndex = num.lastIndexOf('.');
                var intPart = num.substring(0, pointIndex);
                var pointPart = num.substring(pointIndex + 1, num.length);
                intPart = intPart + '';
                var re = /(-?\d+)(\d{3})/;
                while (re.test(intPart)) {
                    intPart = intPart.replace(re, '$1,$2');
                }
                num = intPart + '.' + pointPart;
            }
            else {
                num = num + '';
                var re = /(-?\d+)(\d{3})/;
                while (re.test(num)) {
                    num = num.replace(re, '$1,$2');
                }
            }
        }
        return num;
    };
    /**
     * 手机电话号脱敏显示转换器
     */
    FilterService.encryptPhoneNumber = function (number) {
        if (!number || number === '') {
            return '';
        }
        if (number.length >= 11) {
            return number.substr(0, 3) + '****' + number.substr(7);
        }
        else {
            return number.substr(0, 2) + '****' + number.substr(6);
        }
    };
    /**
     * 手机号脱敏显示转换器
     */
    FilterService.encryptPhone = function (value) {
        if (!value || value === '') {
            return '';
        }
        return value.substr(0, 3) + '****' + value.substr(7);
    };
    /**
     * 身份证脱敏显示转换器
     */
    FilterService.encryptIDCardFour = function (value) {
        if (!value || value === '') {
            return '';
        }
        if (!store.state.idCardEncryptionFlag)
            return value.toString();
        // return value.substr(0, 3).padEnd(value.length - 4, '*') + value.substr(-4)
        return value.substr(0, 3) + '****' + value.substr(-4);
    };
    /**
     * 银行卡号脱敏显示转换器
     */
    FilterService.encryptBankCardNumber = function (value) {
        if (!value || value === '') {
            return '';
        }
        return value.substr(0, 5) + '****' + value.substr(-4);
    };
    /**
     * 长度过长省略显示
     */
    FilterService.ellipsis = function (value, length) {
        if (!value) {
            return '';
        }
        var data = String(value).trim();
        if (data.length <= length) {
            return data;
        }
        else {
            return data.substr(0, length) + '...';
        }
    };
    /**
     * 小数转换为百分比
     * @param point 要转换的小数
     */
    FilterService.toPercent = function (point) {
        var str = '--';
        if (point !== undefined) {
            point *= 100;
            str = (point > 0 ? point.toFixed(2) : 0) + '%';
        }
        return str;
    };
    /**
     *
     * @param value 要格式化的金额字符串
     * @param roundPre 精度 默认为2
     */
    FilterService.moneyFormat = function (value, roundPre) {
        if (roundPre === void 0) { roundPre = 2; }
        var result = LodashService.round(value, roundPre);
        return ("" + result).replace(/^\d+/g, function (m) { return m.replace(/(?=(?!^)(\d{3})+$)/g, ','); });
    };
    /**
     * 格式化显示金额追加百分比符号
     * @param value 要格式化的金额
     */
    FilterService.percentFormat = function (value) {
        if (!value && value !== 0)
            return null;
        var result = LodashService.round(value, 4);
        return result + "%";
    };
    /**
     *
     * @param value 要格式化的金额或字符串
     * @param d 要保留的小数位数
     */
    FilterService.decimalToPrecent = function (value, d) {
        if (d === void 0) { d = 2; }
        if (!value) {
            return '0';
        }
        var f = 0;
        var result = '';
        f = parseFloat(value) || f;
        result = LodashService.round(f * 100, d);
        return FilterService.percentFormat(result);
    };
    /**
     * 银行卡号码格式化
     * @param value 要格式化的银行卡号
     */
    FilterService.formatBankCardNumber = function (value) {
        var result = new String(value);
        return result.replace(/(\d{4})(?=\d)/g, "$1" + "-");
    };
    /**
     * 计算日期间隔天数
     * @param date
     */
    FilterService.leftDays = function (date) {
        if (!date)
            return "";
        var days = differenceInCalendarDays(date, Date.now());
        return Math.abs(days).toString();
    };
    /**
    * 计算结束日期与开始日期间隔天数
    * @param startDate
    * @param endDate
    */
    FilterService.dateLong = function (startDate, endDate) {
        if (!startDate && !endDate)
            return "";
        var days = differenceInCalendarDays(endDate, startDate);
        return Math.abs(days).toString();
    };
    return FilterService;
}());
export { FilterService };
