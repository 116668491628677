var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import SvgIcon from "~/components/common/svg-icon.vue";
import { State, Mutation, namespace, Getter } from "vuex-class";
var workspaceModule = namespace("workspace");
var WorkMenuTop = /** @class */ (function (_super) {
    __extends(WorkMenuTop, _super);
    function WorkMenuTop() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._currentMenu = "";
        return _this;
    }
    Object.defineProperty(WorkMenuTop.prototype, "currentMenu", {
        get: function () {
            return this._currentMenu;
        },
        set: function (value) {
            if (value !== this._currentMenu) {
                this._currentMenu = value;
                var menu = this.workTopMenu.find(function (x) { return x.id == value; });
                if (menu) {
                    this.updateCurrentTopMenu(menu);
                }
            }
        },
        enumerable: false,
        configurable: true
    });
    WorkMenuTop.prototype.created = function () {
        this.currentMenu = (this.currentTopMenu || this.workTopMenu[0]).id.toString();
    };
    __decorate([
        State
    ], WorkMenuTop.prototype, "currentTopMenu", void 0);
    __decorate([
        Mutation
    ], WorkMenuTop.prototype, "updateCurrentTopMenu", void 0);
    __decorate([
        State
    ], WorkMenuTop.prototype, "menuResource", void 0);
    __decorate([
        Getter
    ], WorkMenuTop.prototype, "workTopMenu", void 0);
    WorkMenuTop = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon
            }
        })
    ], WorkMenuTop);
    return WorkMenuTop;
}(Vue));
export default WorkMenuTop;
