import { render, staticRenderFns } from "./work-task.vue?vue&type=template&id=bc7b17aa&scoped=true"
import script from "./work-task.vue?vue&type=script&lang=ts"
export * from "./work-task.vue?vue&type=script&lang=ts"
import style0 from "./work-task.vue?vue&type=style&index=0&id=bc7b17aa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc7b17aa",
  null
  
)

export default component.exports