import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "systemLogController";
export default {
    /**
     * 系统登录日志查询
     */
    findAllLoginLog: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllLoginLog',
        type: requestType.Get
    },
    /**
     * 批量删除登录日志
     */
    batchDeleteLog: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'batchDeleteLog',
        type: requestType.Delete
    },
    /**
     * 系统日志查询
     */
    findAllSystemLog: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllSystemLog',
        type: requestType.Get
    },
    /**
     * 批量删除系统日志
     */
    batchDeleteSystemLog: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'batchDeleteSystemLog',
        type: requestType.Delete
    }
};
