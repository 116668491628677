import { requestType } from "~/config/enum.config";
var SERVICE = "report-service";
var CONTROLLER = "QueryOutsourcePoolController";
export default {
    /**
     * 委外调收中查询
     */
    queryAllOutsourcePool: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryAllOutsourcePool",
        type: requestType.Get,
    },
    /**
     * 委外调收中查询
     */
    queryDistribute: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryDistribute",
        type: requestType.Get,
    },
    /**
     *委外回收案件查询
     */
    queryReturn: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryReturn",
        type: requestType.Get,
    },
};
