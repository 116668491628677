import { requestType } from "~/config/enum.config";
var SERVICE = "dataimp-service";
var CONTROLLER = "homeNoticeController";
export default {
    /**
     * 查询备忘录
     */
    getComment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getComment",
        type: requestType.Get,
    },
    /**
     * 明星调收员
     */
    getStarCollector: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getStarCollector",
        type: requestType.Get,
    },
    /**
     * 明星调收部门
     */
    getStarOrganization: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getStarOrganization",
        type: requestType.Get,
    },
    /**
     * 查询待办事项
     */
    getWaitHandleList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getWaitHandleList",
        type: requestType.Get,
    },
    /**
     * 新增备忘录
     */
    addRemark: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addRemark",
        type: requestType.Post,
    },
    modifyRemark: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "modifyRemark",
        type: requestType.Put,
    },
    deleteRemark: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteRemark",
        type: requestType.Delete,
    },
};
