import { requestType } from '~/config/enum.config';
var SERVICE = 'management-service';
var CONTROLLER = 'configFlowController';
export default {
    /**
     * 配置流程查询
     */
    configFlowQuery: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "configFlowQuery",
        type: requestType.Get
    },
    /**
     * 新建配置流程
     */
    createConfigFlow: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "createConfigFlow",
        type: requestType.Post
    },
    /**
     * 删除配置流程
     */
    deleteConfigFlow: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteConfigFlow",
        type: requestType.Delete
    },
    /**
     * 查询配置流程详情
     */
    findConfigFlow: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findConfigFlow",
        type: requestType.Get
    },
    /**
     * 修改配置流程
     */
    modifyConfigFlow: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "modifyConfigFlow",
        type: requestType.Post
    },
    /**
     * 根据申请类型和审批类型查询唯一流程
     */
    findConfigFlowByType: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findConfigFlowByType",
        type: requestType.Get
    },
};
