import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'approvalExportManageController';
export default {
    /**
     * 审批信息导出
     */
    approvalMessageExport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'approvalMessageExport',
        type: requestType.Post
    },
    /**
     * 导入审批
     */
    approvalByImportData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'approvalByImportData',
        type: requestType.Post
    },
    /**
     * 导入审批
     */
    exportPaymentCheck: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'exportPaymentCheck',
        type: requestType.Post
    },
    /**
     * 导入审批
     */
    importPaymentCheck: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'importPaymentCheck',
        type: requestType.Put
    },
};
