import { requestType } from "~/config/enum.config";
var SERVICE = "management-service";
var CONTROLLER = "custConfig";
export default {
    /**
     * 查询调记配置
     */
    getConfigByPrin: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getConfigByPrin",
        type: requestType.Get,
    },
    /**
     * 查询所有颜色
     */
    getAllColors: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllColors",
        type: requestType.Get,
    },
    /**
     *
     */
    getPrimaryFields: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getPrimaryFields",
        type: requestType.Get,
    },
    /**
     * 查询关系/电话类型/地址类型
     */
    getRelationShip: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getRelationShip",
        type: requestType.Get,
    },
    /**
     * 查询关系/电话类型/地址类型
     */
    getPhoneType: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getPhoneType",
        type: requestType.Get,
    },
    /**
     * 查询地址类型
     */
    getAddressType: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAddressType",
        type: requestType.Get,
    },
    /**
     * 查询地址状态
     */
    getAddressStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAddressStatus",
        type: requestType.Get,
    },
    /**
     * 查询联络结果
     */
    getContactResult: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getContactResult",
        type: requestType.Get,
    },
};
