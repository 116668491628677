import { requestType } from "~/config/enum.config";
var SERVICE = "business-service";
var CONTROLLER = "complianceCheckController";
export default {
    /**
     * 新增配置
     */
    createComplianceCheck: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'createComplianceCheck',
        type: requestType.Post
    },
    /**
     * 配置查询
     */
    getAllComplianceCheck: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getAllComplianceCheck',
        type: requestType.Get
    },
    /**
     * 删除配置
     */
    deleteComplianceCheck: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteComplianceCheck',
        type: requestType.Delete
    },
    /**
     * 更改状态
     */
    changeState: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'changeState',
        type: requestType.Put
    },
};
