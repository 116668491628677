var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CommonService } from "~/utils/common.service";
export default {
    /**
     * 用户部门数据
     * @param param0
     */
    departmentData: function (state) {
        var departmentList = state.departmentList.map(function (v) { return (__assign({}, v)); });
        return CommonService.generateTreeData(departmentList, {
            keyName: 'id',
            parentKeyName: 'parent'
        });
    },
    /**
     * 返回是否超级管理员
    */
    isSupperAdmin: function (state) {
        return state.userData.id === "0o0oo0o0-0o0o-0000-0000-0ooo000o0o0o";
    },
    /**
     * 获取工作台顶部menu列表
     * @param state
     */
    workTopMenu: function (state) {
        return state.menuResource
            .filter(function (x) { return x.level === 1; })
            .sort(function (x, y) { return x.sort - y.sort; });
    },
    getAllParentId: function (state) {
        var findParent = function (id) {
            var current = state.departmentList.find(function (x) { return x.id === id; });
            if (!current)
                return [id];
            var parent = state.departmentList.find(function (x) { return x.id === current.parent; });
            if (parent) {
                return findParent(parent.id).concat(current.id);
            }
            else {
                return [current.id];
            }
        };
        return findParent;
    },
    orgTreeData: function (state) {
        var orgList = state.orgInfo.map(function (v) { return (__assign({}, v)); });
        var option = {
            keyName: 'id',
            parentKeyName: 'parent'
        };
        return CommonService.generateTreeData(orgList, option);
    },
};
