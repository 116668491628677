import { requestType } from "~/config/enum.config";
var SERVICE = "business-service";
var CONTROLLER = "caseApplyManageController";
export default {
    /**
     * 申请信息查询
     */
    getProcessApplicationByFlowType: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getProcessApplicationByFlowType",
        type: requestType.Get,
    },
    /**
     * 查询可以选择的审批人信息
     */
    caseApplyOperated: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "caseApplyOperated",
        type: requestType.Post,
    },
    /**
     * 根据申请查询流程节点
     */
    getProcessFlowNodes: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getProcessFlowNodes",
        type: requestType.Get,
    },
    /**
     * 各类审批查询
     */
    getProcessApproveMessage: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getProcessApproveMessage",
        type: requestType.Get,
    },
    /**
     * 审批操作
     */
    caseApplyApprove: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "caseApplyApprove",
        type: requestType.Post,
    },
    /**
     * 协调管理查外访案件
     */
    searchAssistCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchAssistCase",
        type: requestType.Get,
    },
    /**
     * 批量审批
     */
    approveByBatch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "approveByBatch",
        type: requestType.Post,
    },
    /**
     * 信函打印
     */
    getLetterPrint: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getLetterPrint",
        type: requestType.Get,
    },
    /**
     * 更改信函打印状态
     */
    changePrintStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "changePrintStatus",
        type: requestType.Post,
    },
};
