import { requestType } from "~/config/enum.config";
var SERVICE = "management-service";
var CONTROLLER = "organization";
export default {
    /*
     *获取组织机构
     */
    findAllOrganization: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAll",
        type: requestType.Get,
    },
    /*
     *获取所有组织的父/子机构
     */
    findParentOrSon: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findParentOrSon",
        type: requestType.Get,
    },
    /**
     * 创建获取组织机构
     */
    createOrganization: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "createOrganization",
        type: requestType.Post,
    },
    /**
     * 修改获取组织机构
     */
    modifyOrganization: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "modify",
        type: requestType.Post,
    },
    /**
     * 删除获取组织机构
     */
    deleteOrganization: {
        service: SERVICE,
        controller: CONTROLLER,
        type: requestType.Delete,
    },
    /**
     * 根据组织机构id获取对应用户
     */
    queryByOrganizationId: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryByOrganizationId",
        type: requestType.Get,
    },
    /**
     * 获取所有的分公司
     */
    findAllBranch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllBranch",
        type: requestType.Get,
    },
    /**
     * 根据组织机构id获取对应员工
     */
    queryUserByOrganizationId: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryUserByOrganizationId",
        type: requestType.Get,
    },
    /**
     *
     */
    getAllHighBranch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllHighBranch",
        type: requestType.Get,
    },
    /**
     * 查询机构下的用户
     */
    queryOperatorByOrgId: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryOperatorByOrgId",
        type: requestType.Get,
    },
    /**
     * 获取当前登陆人下的调收员
     */
    queryUserByDepartId: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryUserByDepartId",
        type: requestType.Get,
    },
    /**
     * 查询可以选择的操作员信息
     */
    findChooseOperatorList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findChooseOperatorList",
        type: requestType.Get,
    },
};
