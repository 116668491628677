var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import principalManange from "./principal-manange.route";
import taticsManage from './tatics-manage.route';
import templateManage from './template-manage.route';
import systemManage from './system-manage.route';
import caseStatusMange from './case-status-manage.route';
import processManage from "./process-config.route";
import logsManage from "./logs-manage.route";
export default __spreadArrays(principalManange, taticsManage, templateManage, systemManage, caseStatusMange, processManage, logsManage);
