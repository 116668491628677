import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'collectionCaseStrategyConfig';
export default {
    /**
     * 新增
     */
    addStrategy: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addStrategy',
        type: requestType.Post
    },
    /**
     * 修改
     */
    editStrategy: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'editStrategy',
        type: requestType.Put
    },
    /**
     * 查询策略
     */
    searchStrategy: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchStrategy',
        type: requestType.Get
    }
};
