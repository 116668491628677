import { requestType } from "~/config/enum.config";
var SERVICE = "management-service";
var CONTROLLER = "exportConfigController";
export default {
    /**
     * 新建模板
     */
    createExportConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "createExportConfig",
        type: requestType.Post,
    },
    /**
     * 删除模板
     */
    deleteExportConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteExportConfig",
        type: requestType.Delete,
    },
    /**
     * 修改模板配置
     */
    exportConfigModify: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportConfigModify",
        type: requestType.Post,
    },
    /**
     * 获取所有模板
     */
    getAllTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllTemplate",
        type: requestType.Get,
    },
    /**
     * 获取各个委托方调记字段
     */
    getFollowConfigItems: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getFollowConfigItems",
        type: requestType.Get,
    },
    /**
     * 获取各个委托方主键关联字段
     */
    getPrimaryProperty: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getPrimaryProperty",
        type: requestType.Get,
    },
    /**
     * 获取指定模板
     */
    getTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getTemplate",
        type: requestType.Get,
    },
    /**
     * 获取所有模板选项
     */
    getAllConfigs: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllConfigs",
        type: requestType.Get,
    },
    /**
     * 解析Excel数据模板头信息
     */
    parserTemplateHeader: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "parserTemplateHeader",
        type: requestType.Post,
    },
    /**
     * 获取模板列表
     */
    getTemplateByPrin: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getTemplateByPrin",
        type: requestType.Get,
    },
};
