import { requestType } from '~/config/enum.config';
var SERVICE = 'management-service';
var CONTROLLER = 'impDataToSysDataController';
export default {
    /**
     * 获取上传excel文件列表
     */
    parseExcelCols: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "parseExcelCols",
        type: requestType.Post
    },
    /**
     * 执行合并请求
     */
    impDataToSysData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "impDataToSysData",
        type: requestType.Post
    },
    /**
     * 行转换列
     */
    rowToCol: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "rowToCol",
        type: requestType.Post
    }
};
