import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "noticeController";
export default {
    /**
     * 多条件查询所有公告
     */
    findAllNotice: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllNotice',
        type: requestType.Get
    },
    /**
     * 新增公告
     */
    addNotice: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addNotice',
        type: requestType.Post
    },
    /**
     * 删除公告
     */
    deleteNotice: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteNotice',
        type: requestType.Delete
    },
    /**
     * 修改公告
     */
    modifyNotice: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyNotice',
        type: requestType.Put
    },
    /**
     * 首页公告列表查询
     */
    getNoticeList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getNoticeList',
        type: requestType.Get
    }
};
